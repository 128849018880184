export const GET_APPS = 'GET_APPS'
export const GET_ME = 'GET_ME'
export const GET_ORG = 'GET_ORG'
export const GET_ORG_CATALOG_SETTINGS = 'GET_ORG_CATALOG_SETTINGS'
export const GET_ORG_SUPPORTED_FEATURES = 'GET_ORG_SUPPORTED_FEATURES'
export const GET_USER_APPS = 'GET_USER_APPS'

export const LOAD_USER_PREFERENCES = 'LOAD_USER_PREFERENCES'
export const TOGGLE_FILTER_CATEGORY = 'TOGGLE_FILTER_CATEGORY'
export const TOGGLE_FILTER_MY_APPS = 'TOGGLE_FILTER_MY_APPS'
export const TOGGLE_SHOW_APP_DETAILS = 'TOGGLE_SHOW_APP_DETAILS'

export const CHANGE_SEARCH = 'CHANGE_SEARCH'
export const SET_SEARCH_LOADING = 'SET_SEARCH_LOADING'
export const REQUEST_ACCESS = 'REQUEST_ACCESS'
export const GET_SIMILAR_APPS = 'GET_SIMILAR_APPS'

export const SEARCH_APPS = 'SEARCH_APPS'
export const GET_ALL_APPS_FROM_APPS_DB = 'GET_ALL_APPS_FROM_APPS_DB'

export const MOBILE_BREAKPOINT = '768px'
export const MULTIPLE_BOX_IN_ROW_BREAKPOINT = '1024px'

export const AUTH_STATE = {
  BEFORE_AUTH: 'BEFORE_AUTH',
  PENDING: 'PENDING',
  AUTHENTICATED: 'AUTHENTICATED',
  NOT_AUTHENTICATED: 'NOT_AUTHENTICATED',
  NO_LONGER_AUTHENTICATED: 'NO_LONGER_AUTHENTICATED'
}

export const FORM_FIELD_TYPES = {
  singleLine: 'singleLine',
  multiLine: 'multiLine',
  number: 'number',
  currency: 'currency',
  datePicker: 'datePicker',
  dropdown: 'dropdown',
  dropdownMulti: 'dropdownMulti',
  usersDropdown: 'usersDropdown',
  usersDropdownMulti: 'usersDropdownMulti'
}

export const VALID_FIELD_TYPES = {
  ...FORM_FIELD_TYPES,
  tags: 'tags'
}

export const DEFAULT_SEARCH_APPS_LIMIT = 20
export const MAX_SELECT_ITEMS_TO_SHOW = 500
