import React, { Fragment } from 'react'
import { Icon, Spacer } from '@toriihq/design-system'
import { css } from 'glamor'
import groupBy from 'lodash/groupBy'

import { MOBILE_BREAKPOINT, MULTIPLE_BOX_IN_ROW_BREAKPOINT } from '../../constants'
import colors from '../../shared/style/colors'
import texts from '../../shared/style/texts'
import { fontWeight } from '../../shared/style/sizes'
import CategoriesFilters from '../../components/categoriesFilters'
import Search from '../../components/search'
import PoweredByTorii from '../../components/poweredByTorii'
import MyAppsFilter from '../../components/myAppsFilter'
import FiltersPopup from '../../components/popups/filtersPopup'
import Apps from '../../components/apps'
import Menu from '../../components/menu'
import RequestNewAppButton from '../../components/requestNewApp/'
import AppAttributes from '../../components/appAttributes'
import '../../shared/style/animate.less'
import PreviewImage from '../../shared/images/preview.svg'
import SearchMatchingAppThatIsNotInCatalog from '../../components/SearchMatchingAppThatIsNotInCatalog'

const LEFT_MENU_WIDTH = '230px'
const RIGHT_MENU_WIDTH = '331px'

const CSS = {
  mainContainer: css({
    display: 'grid',
    gridTemplateColumns: `${LEFT_MENU_WIDTH} calc(100% - ${LEFT_MENU_WIDTH} - ${RIGHT_MENU_WIDTH}) ${RIGHT_MENU_WIDTH}`,
    width: '100%',
    height: '100%',
    [`@media(max-width: ${MULTIPLE_BOX_IN_ROW_BREAKPOINT})`]: {
      gridTemplateColumns: `calc(100% - ${RIGHT_MENU_WIDTH}) ${RIGHT_MENU_WIDTH}`
    },
    [`@media(max-width: ${MOBILE_BREAKPOINT})`]: {
      gridTemplateColumns: 'none'
    }
  }),
  left: css({
    position: 'sticky',
    height: '100vh',
    padding: '50px 0',
    backgroundColor: colors.lightBlue3,
    flexDirection: 'column',
    top: 0,
    [`@media(max-width: ${MULTIPLE_BOX_IN_ROW_BREAKPOINT})`]: {
      display: 'none'
    }
  }),
  center: css({
    padding: '50px 27px',
    height: '100vh',
    overflow: 'auto',
    [`@media(max-width: ${MULTIPLE_BOX_IN_ROW_BREAKPOINT})`]: {
      padding: '50px 20px'
    }
  }),
  right: css({
    backgroundColor: colors.white,
    borderLeft: `1px solid ${colors.lightBlue2}`,
    position: 'sticky',
    height: '100vh',
    top: 0,
    overflow: 'auto',
    [`@media(max-width: ${MOBILE_BREAKPOINT})`]: {
      animationDuration: '0.3s',
      animationFillMode: 'both',
      animationName: 'fadeInUp',
      height: 'unset',
      border: 'none'
    }
  }),
  leftContainer: css({
    width: '100%',
    maxWidth: '160px',
    marginLeft: 'auto',
    marginRight: 'auto'
  }),
  centerContainer: css({
    position: 'relative',
    width: '100%'
  }),
  headerContainer: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }),
  header: css({
    fontSize: '30px',
    fontWeight: fontWeight.bold,
    flex: 1,
    [`@media(max-width: ${MOBILE_BREAKPOINT})`]: {
      fontSize: '20px'
    }
  }),
  filtersButton: css({
    height: '20px',
    border: 'none',
    display: 'none',
    backgroundColor: 'transparent',
    [`@media(max-width: ${MULTIPLE_BOX_IN_ROW_BREAKPOINT})`]: {
      display: 'inline-block'
    }
  }),
  searchBox: css({
    marginTop: 36,
    [`@media(max-width: ${MOBILE_BREAKPOINT})`]: {
      marginTop: 19
    }
  }),
  applications: css({
    marginTop: 40,
    [`@media(max-width: ${MOBILE_BREAKPOINT})`]: {
      marginTop: 20
    }
  }),
  category: css(texts.heading, {
    marginTop: '20px',
    marginBottom: '20px',
    textTransform: 'uppercase'
  }),
  similarAppsCategory: css(texts.heading, {
    marginTop: '30px',
    marginBottom: '10px',
    textTransform: 'uppercase'
  }),
  logoContainer: css({
    position: 'relative',
    height: '30px',
    width: '100%',
    overflow: 'hidden'
  }),
  logo: css({
    position: 'absolute',
    top: 0,
    left: 0,
    maxWidth: '100%',
    maxHeight: '100%',
    [`@media(max-width: ${MOBILE_BREAKPOINT})`]: {
      position: 'relative',
      height: '25px'
    }
  }),
  companyName: css({
    fontSize: '20px',
    fontWeight: fontWeight.semiBold
  }),
  headerBox: css({
    display: 'flex',
    alignItems: 'center'
  }),
  bigScreenLogout: css({
    [`@media(max-width: ${MOBILE_BREAKPOINT})`]: {
      display: 'none'
    }
  }),
  smallScreenHeader: css({
    padding: '15px 10px',
    background: colors.lightBlue3,
    display: 'none',
    [`@media(max-width: ${MOBILE_BREAKPOINT})`]: {
      display: 'flex'
    }
  }),
  smallScreenOrgName: css({
    flex: 1
  }),
  hideInMobile: css({
    [`@media(max-width: ${MOBILE_BREAKPOINT})`]: {
      display: 'none'
    }
  }),
  hideInBigScreen: {
    [`@media(min-width: ${MOBILE_BREAKPOINT})`]: {
      display: 'none'
    }
  },
  showInMobile: css({
    [`@media(max-width: ${MOBILE_BREAKPOINT})`]: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      top: 30,
      borderRadius: '20px 20px 0 0'
    }
  }),
  mobileContainer: css({
    [`@media(max-width: ${MOBILE_BREAKPOINT})`]: {
      position: 'fixed',
      display: 'block',
      height: '100%',
      width: '100%',
      bottom: 0,
      transition: 'background 0.5s',
      background: 'rgba(0, 0, 0, 0.8)'
    }
  }),
  noAppFoundText: css(texts.body, {
    color: colors.darkText
  }),
  noAppSelectedContainer: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  }),
  noAppSelectedText: css(texts.body, {
    color: colors.darkBlue2
  }),
  checkboxWrapper: css({
    display: 'block'
  })
}

class AppsCatalogPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = { isFiltersPopupOpen: false }
    this.toggleFiltersPopup = this.toggleFiltersPopup.bind(this)
    this.renderSearchResults = this.renderSearchResults.bind(this)
  }

  componentDidMount () {
    const { idOrg, idUser, getAppsFromServer, getUserAppsFromServer, getOrgCatalogSettings, getSupportedFeatures } = this.props
    getOrgCatalogSettings({ idOrg })
    getAppsFromServer({ idOrg })
    getUserAppsFromServer({ idOrg, idUser })
    getSupportedFeatures({ idOrg })
  }

  toggleFiltersPopup () {
    this.setState({ isFiltersPopupOpen: !this.state.isFiltersPopupOpen })
  }

  renderRightSection () {
    const { selectedApp, catalogSettings } = this.props
    if (selectedApp) {
      return <AppAttributes app={selectedApp} />
    }
    if (catalogSettings.isRequestLicenseEnabled === undefined) {
      return (
        <div {...CSS.noAppSelectedContainer}>
          <img src={PreviewImage} alt='no_app_selected' width={310} />
        </div>
      )
    }
    return (
      <div {...CSS.noAppSelectedContainer}>
        <img src={PreviewImage} alt='no_app_selected' width={310} height={367} />
        <span {...CSS.noAppSelectedText}>Select application to view details</span>
        {catalogSettings.isRequestLicenseEnabled ? <span {...CSS.noAppSelectedText}>and request access</span> : null}
      </div>
    )
  }

  renderSearchResults (categories) {
    const { similarAppsInCatalog, searchLoading, searchMatchingAppThatIsNotInCatalog, search, loading } = this.props

    if (Object.keys(categories).length || loading) {
      return null
    }

    if (searchLoading) {
      return <span {...texts.body}>Searching for apps...</span>
    }

    if (searchMatchingAppThatIsNotInCatalog) {
      return <div>
        <SearchMatchingAppThatIsNotInCatalog />
        {!similarAppsInCatalog.length ? <div {...CSS.noAppFoundText}><strong>We’re sorry</strong>, the app you are looking for is not available in the catalog.</div> : (
          <div>
            <div {...CSS.noAppFoundText}>
              <strong>We’re sorry</strong>, the app you are looking for is not available in the catalog. <br />However, we found similar apps that we recommend you explore!
            </div>
            <div>
              <div {...CSS.similarAppsCategory}>{searchMatchingAppThatIsNotInCatalog.category}</div>
              <Apps apps={similarAppsInCatalog} />
            </div>
          </div>
        )}
      </div>
    }

    return search ? <span {...texts.body}>No applications found for "{search}"</span> : null
  }
  render () {
    const { user, apps, checkboxes, companyName, logoUrl, isWhiteLabel, loading, selectedApp } = this.props
    const { isFiltersPopupOpen } = this.state
    const categories = groupBy(apps, 'category')
    const categoryNames = Object.keys(categories).sort()
    const emptyPoweredBy = <div style={{ height: '28px' }} />
    const showPoweredBy = !loading && !isWhiteLabel
    return (
      <Fragment>
        <div {...CSS.smallScreenHeader}>
          <div {...CSS.smallScreenOrgName}>
            {logoUrl ? <img {...CSS.logo} alt='Company Logo' src={logoUrl} /> : companyName}
          </div>
          <div><Menu firstName={user.firstName} lastName={user.lastName} /></div>
        </div>
        <div {...CSS.mainContainer}>
          <div {...CSS.left}>
            <div {...CSS.leftContainer}>
              {logoUrl
                ? <div {...CSS.logoContainer}><img {...CSS.logo} alt='Company Logo' src={logoUrl} /></div>
                : <div {...CSS.companyName}>{companyName}</div>
              }
              <div {...CSS.checkboxWrapper}>
                <Spacer top={'space-400'}>
                  <MyAppsFilter />
                </Spacer>
              </div>
              <CategoriesFilters categories={checkboxes} />
            </div>
          </div>
          <div {...CSS.center}>
            <div {...CSS.centerContainer}>
              <div {...CSS.headerContainer}>
                <div {...CSS.header}>Application Catalog</div>
                <div {...CSS.bigScreenLogout} ><Menu firstName={user.firstName} lastName={user.lastName} /></div>
                <button {...CSS.filtersButton} onClick={this.toggleFiltersPopup}><Icon name='Filter' color='primary' /></button>
              </div>
              {showPoweredBy ? <PoweredByTorii /> : emptyPoweredBy}
              <div {...CSS.searchBox}>
                <Search />
              </div>
              <RequestNewAppButton />
              <div {...CSS.applications}>
                {categoryNames.map(category =>
                  <div key={category}>
                    <div {...CSS.category}>{!loading && category}</div>
                    <Apps apps={categories[category]} loading={loading} />
                  </div>
                )}
                {this.renderSearchResults(categories)}
              </div>
            </div>
          </div>
          <span id='mobile-container' {...css(CSS.hideInBigScreen, selectedApp && CSS.mobileContainer)} />
          <div id='right-section' {...css(CSS.right, !selectedApp ? CSS.hideInMobile : CSS.showInMobile)}>
            {this.renderRightSection()}
          </div>
        </div>
        <FiltersPopup cancel={this.toggleFiltersPopup} isOpen={isFiltersPopupOpen} categories={checkboxes} loading={loading} />
      </Fragment>
    )
  }
}

export default AppsCatalogPage
