import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import { controlStyle } from '../formStyle'

const CSS = {
  textarea: css(controlStyle, {
    minHeight: '80px',
    maxWidth: '430px',
    padding: '10px'
  }),
  noBorder: css({
    border: 'none',
    ':hover': {
      border: 'none'
    },
    ':focus': {
      border: 'none'
    }
  })
}

const Textarea = ({ border, overrideStyle, ...rest }) => (
  <textarea
    {...css(CSS.textarea, !border && CSS.noBorder, overrideStyle)}
    {...rest}
  />
)

Textarea.propTypes = {
  overrideStyle: PropTypes.object,
  border: PropTypes.bool
}

Textarea.defaultProps = {
  border: true
}

export default Textarea
