import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getSelf } from '../../selectors/me'

const mapStateToProps = (state) => {
  const me = getSelf(state)

  return {
    me
  }
}

export default withRouter(connect(mapStateToProps)(View))
