import View from './view'
import { connect } from 'react-redux'
import { requestAccess, toggleShowAppDetails } from '../../shared/actions'
import { getSelf } from '../../selectors/me'
import { getCurrentOrg, getCatalogSettings } from '../../selectors/org'
import { getAppsResources } from '../../selectors/apps'

const mapStateToProps = (state, ownProps) => {
  const self = getSelf(state)
  const catalogSettings = getCatalogSettings(state)
  const appsResources = getAppsResources(state)
  const { defaultCurrency } = getCurrentOrg(state) || {}
  const { app, loading } = ownProps

  return {
    catalogSettings,
    appsResources,
    users: appsResources.users,
    self,
    app,
    loading,
    defaultCurrency
  }
}

const mapDispatchToProps = {
  requestAccess,
  toggleShowAppDetails
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
