import { CHANGE_SEARCH, GET_SIMILAR_APPS } from '../constants'

const initialState = {
  loading: false,
  similarApps: [],
  searchMatchingAppThatIsNotInCatalog: undefined
}

const similarApps = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_SIMILAR_APPS}_PENDING`: {
      return {
        ...state,
        loading: true
      }
    }
    case `${GET_SIMILAR_APPS}_FAILED`: {
      return {
        ...state,
        loading: false
      }
    }
    case `${GET_SIMILAR_APPS}_RESPONSE`: {
      const { similarAppsInCatalog, app } = action.payload
      return {
        ...state,
        loading: false,
        similarApps: similarAppsInCatalog,
        searchMatchingAppThatIsNotInCatalog: app
      }
    }
    case CHANGE_SEARCH: {
      return { similarApps: [], searchMatchingAppThatIsNotInCatalog: undefined }
    }

    default: {
      return state
    }
  }
}

export default similarApps
