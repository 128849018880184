import keyBy from 'lodash/keyBy'
import unionBy from 'lodash/unionBy'

import {
  GET_APPS,
  GET_USER_APPS,
  REQUEST_ACCESS,
  GET_ALL_APPS_FROM_APPS_DB
} from '../constants'

const initialState = {
  loadingApps: false,
  loadingAppsFromAppsDB: false,
  loadingUserApps: false,
  apps: [],
  appsFromAppsDB: [],
  resources: {
    fields: {},
    users: {}
  },
  userAppsIds: []
}

const appsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${GET_APPS}_PENDING`: {
      return {
        ...state,
        loadingApps: true
      }
    }
    case `${GET_APPS}_FAILED`: {
      return {
        ...state,
        loadingApps: false
      }
    }
    case `${GET_APPS}_RESPONSE`: {
      const oldApps = state.apps
      const prevAppsByIdApp = keyBy(oldApps, 'id')

      const newApps = action.payload.apps.map(app => {
        const oldApp = prevAppsByIdApp[app.id] || {}
        return {
          ...oldApp,
          ...app
        }
      })

      const apps = unionBy(newApps, oldApps, 'id')
      return {
        ...state,
        apps,
        resources: {
          fields: keyBy(action.payload.resources.fields, 'systemKey'),
          users: action.payload.resources.users
        },
        loadingApps: false
      }
    }

    case `${GET_USER_APPS}_PENDING`: {
      return {
        ...state,
        loadingUserApps: true
      }
    }
    case `${GET_USER_APPS}_FAILED`: {
      return {
        ...state,
        loadingUserApps: false
      }
    }
    case `${GET_USER_APPS}_RESPONSE`: {
      const userAppsIds = action.payload.apps.map(app => app.idApp || app.id)

      return {
        ...state,
        userAppsIds,
        loadingUserApps: false
      }
    }
    case `${REQUEST_ACCESS}_RESPONSE`: {
      return {
        ...state
      }
    }
    case `${GET_ALL_APPS_FROM_APPS_DB}_PENDING`: {
      return {
        ...state,
        loadingAppsFromAppsDB: true
      }
    }
    case `${GET_ALL_APPS_FROM_APPS_DB}_FAILED`: {
      return {
        ...state,
        loadingAppsFromAppsDB: false
      }
    }
    case `${GET_ALL_APPS_FROM_APPS_DB}_RESPONSE`: {
      return {
        ...state,
        appsFromAppsDB: action.payload.apps,
        loadingAppsFromAppsDB: false
      }
    }
    default: {
      return state
    }
  }
}

export default appsReducer
