import React from 'react'
import { css } from 'glamor'
import texts from '../../shared/style/texts'
import { fontWeight } from '../../shared/style/sizes'
import colors from '../../shared/style/colors'
import SmallToriiLogo from '@media/small_torii_logo.svg'

const CSS = {
  powered: css(texts.body, {
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    fontWeight: fontWeight.semiBold,
    color: colors.grey1,
    ' > img': {
      position: 'relative',
      top: '-1px',
      marginLeft: '5px'
    }
  })
}

const PoweredByTorii = () => (
  <div {...CSS.powered}>
    <span>Powered by</span>
    <img src={SmallToriiLogo} alt='Torii logo' />
  </div>
)

export default PoweredByTorii
