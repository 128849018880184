import React, { Fragment, useContext } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import * as Style from './style'
import { css } from 'glamor'
import { ToriiPopupContext, ToriiPopupProvider } from './popupContext'
import RenderModals from './renderModals'
import { Button, ButtonSize, ButtonType } from '@toriihq/design-system'

const getHeaderAndFooterHeight = () => {
  const popupHeaderArea = document.getElementById('popupHeaderArea')
  const popupFooterArea = document.getElementById('popupFooterArea')

  return (popupHeaderArea && popupFooterArea) ? popupHeaderArea.clientHeight + popupFooterArea.clientHeight : 0
}

const ToriiPopup = (props) => {
  const {
    children,
    closeOnOverlayClick = true,
    modalOverrideStyle,
    styles,
    isOpen,
    confirmClose = false,
    width,
    height,
    onCloseAction = () => {}
  } = props

  return (
    <ToriiPopupProvider confirmClose={confirmClose} onCloseAction={onCloseAction}>
      <RenderModals children={children} closeOnOverlayClick={closeOnOverlayClick} modalOverrideStyle={modalOverrideStyle} styles={styles} isOpen={isOpen} onCloseAction={onCloseAction} width={width} height={height} />
    </ToriiPopupProvider>
  )
}

ToriiPopup.Header = (props) => {
  const { header, subHeader, children } = props

  return (
    <div {...Style.HeaderContainer} id='popupHeaderArea'>
      {header &&
        <div {...Style.Header}>
          {header}
        </div>
      }
      {subHeader &&
        <div {...Style.SubHeader}>
          {subHeader}
        </div>
      }
      {children &&
        <div {...Style.CustomHeader}>
          {children}
        </div>
      }
    </div>
  )
}

ToriiPopup.Content = (props) => {
  const { children, contentAreaStyle } = props

  return (
    <section {...css(Style.ContentArea(getHeaderAndFooterHeight()), contentAreaStyle)} id='contentArea'>
      {children}
    </section>
  )
}

const ToriiPopupForm = (props) => {
  const { onSubmit, validate, render, renderFooter, contentAreaStyle, mutators, initialValues, validateOnBlur } = props

  const { submitAction, initialValues: initialValuesFromContext } = useContext(ToriiPopupContext)

  return (
    <Form
      onSubmit={onSubmit || submitAction.current}
      validate={validate}
      validateOnBlur={validateOnBlur}
      mutators={mutators}
      initialValues={initialValues || initialValuesFromContext}
      render={(formProps) =>
        <Fragment>
          <section {...css(Style.FormArea(getHeaderAndFooterHeight()), contentAreaStyle)} id='contentArea'>
            {render(formProps)}
          </section>
          {renderFooter && renderFooter(formProps)}
        </Fragment>
      }
    />
  )
}
ToriiPopup.Form = ToriiPopupForm

const ToriiPopupFooter = (props) => {
  const {
    showCancelButton = true,
    cancelButtonText,
    showMainButton = true,
    mainButtonText,
    mainButtonAction = () => {},
    isMainButtonDisabled,
    overrideStyle,
    mainButtonType = ButtonType.primary,
    children
  } = props

  const { onClose } = useContext(ToriiPopupContext)

  if (!(showCancelButton || showMainButton || children)) {
    return null
  }

  return (
    <footer {...css(Style.Footer, overrideStyle)} id='popupFooterArea'>
      <div {...Style.Buttons}>
        {showCancelButton && <Button type={ButtonType.secondary} onClick={onClose} size={ButtonSize.medium} label={cancelButtonText} />}
        {showMainButton && <Button onClick={() => mainButtonAction()} size={ButtonSize.medium} disabled={isMainButtonDisabled} label={mainButtonText} type={mainButtonType} />}
        {children}
      </div>
    </footer>
  )
}
ToriiPopup.Footer = ToriiPopupFooter

ToriiPopup.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  closeOnOverlayClick: PropTypes.bool,
  modalOverrideStyle: PropTypes.object,
  styles: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onCloseAction: PropTypes.func,
  confirmClose: PropTypes.bool
}

ToriiPopup.Header.propTypes = {
  header: PropTypes.node,
  subHeader: PropTypes.node
}

ToriiPopup.Content.propTypes = {
  contentAreaStyle: PropTypes.object
}

ToriiPopup.Form.prototypes = {
  onSubmit: PropTypes.func,
  validate: PropTypes.func,
  validateOnBlur: PropTypes.bool,
  render: PropTypes.func,
  mutators: PropTypes.object,
  initialValues: PropTypes.object
}

ToriiPopup.Footer.propTypes = {
  currentPage: PropTypes.number,
  numberOfPages: PropTypes.number,
  showCancelButton: PropTypes.bool,
  cancelButtonText: PropTypes.string,
  cancelButtonAction: PropTypes.func,
  showMainButton: PropTypes.bool,
  scopes: PropTypes.arrayOf(PropTypes.string),
  mainButtonText: PropTypes.string,
  mainButtonAction: PropTypes.func,
  isMainButtonDisabled: PropTypes.bool,
  overrideStyle: PropTypes.object,
  mainButtonType: PropTypes.string
}

export { ToriiPopupContext } from './popupContext'

export default ToriiPopup
