import { css } from 'glamor'

export const oneLiner = css({
  display: 'block',
  width: '100%',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden'
})

export const clearButton = css({
  outline: '0',
  border: 'none',
  background: 'transparent',
  cursor: 'pointer'
})

export const isDisabledCss = css({
  cursor: 'not-allowed !important',
  opacity: 0.5
})
