import { css } from 'glamor'
import colors from '../../../../shared/style/colors'
import texts from '../../../../shared/style/texts'
import { fontWeight } from '../../../../shared/style/sizes'

export const CloseButton = css({
  margin: '16px 16px 0',
  zIndex: 111,
  ':focus': {
    outline: 'none !important'
  }
})

export const Popup = {
  modal: {
    width: '400px',
    height: '261px',
    padding: '0px',
    display: 'flex',
    flexDirection: 'column'
  }
}

export const Modal = css({
  zIndex: 55,
  border: `1px solid ${colors.grey3}`,
  borderRadius: '4px',
  outline: 'none',
  boxShadow: '0 16px 23px 0 rgba(0,0,0,0.24)',
  background: colors.white
})

export const Header = css(texts.headers.regular, {
  fontWeight: fontWeight.bold,
  lineHeight: '24px',
  color: colors.black,
  borderBottom: `1px solid ${colors.border}`,
  padding: '60px 30px 20px',
  display: 'flex',
  flexDirection: 'column'
})

export const ContentArea = css(texts.body, {
  color: colors.black,
  padding: '30px 30px',
  flexGrow: 1,
  overflowY: 'auto'
})

export const Footer = css({
  borderTop: `1px solid ${colors.border}`,
  minHeight: '81px',
  maxHeight: '81px',
  padding: '0 30px',
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 1,
  justifyContent: 'flex-end',
  alignItems: 'center'
})

export const Buttons = css({
  display: 'flex',
  flexDirection: 'row',
  gap: '10px'
})
