import sanitizeHtml from 'sanitize-html'
import get from 'lodash/get'

const placeholdersRegExp = /({.*?})/

const mergeData = (data, defaultValue) => part => {
  const isPlaceholder = placeholdersRegExp.test(part)
  if (!isPlaceholder) {
    return part
  }
  const partWithoutBraces = part
    .split('{').join('')
    .split('}').join('')
  return get(data, partWithoutBraces, defaultValue) || defaultValue
}

export const safeEmailTemplating = (template, data = {}, options = {}, defaultValue = 'UNKNOWN') => {
  const mergedWithData = template
    .split(placeholdersRegExp)
    .map(mergeData(data, defaultValue))
    .join('')

  return sanitizeHtml(mergedWithData, {
    allowedTags: [],
    allowedAttributes: {},
    ...options
  })
}
