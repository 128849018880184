import config from '@envConfig'
import Cookie from 'cookie'

const cookies = document ? Cookie.parse(document.cookie || '') : {}

if (cookies.API_BASE_URL) {
  console.log(`Preview API base URL is set via "API_BASE_URL" to "${cookies.API_BASE_URL}".`)
  config.apiBaseUrl = cookies.API_BASE_URL
  config.proxyApiRequests = false
}

export default config
