import React from 'react'
import Modal from 'react-responsive-modal'
import PropTypes from 'prop-types'
import * as Style from './style'
import { css } from 'glamor'
import { Button, ButtonSize, ButtonType } from '@toriihq/design-system'

const ConfirmationPopup = (props) => {
  const {
    header = 'Quit Unsaved Work?',
    message = 'Closing this window will cancel any changes you made.',
    isOpen
  } = props
  const {
    stayButtonAction = () => {},
    stayButtonText = 'Stay',
    quitButtonAction = () => {},
    quitButtonText = 'Quit'
  } = props

  return (
    <Modal
      closeIconSize={18}
      center
      open={isOpen}
      onClose={stayButtonAction}
      closeOnOverlayClick={false}
      styles={Style.Popup}
      classNames={{ closeButton: Style.CloseButton.toString(), modal: Style.Modal.toString() }}
    >
      <header {...Style.Header}>
        {header}
      </header>
      <section {...css(Style.ContentArea)}>
        {message}
      </section>
      <footer {...Style.Footer}>
        <div {...Style.Buttons}>
          <Button onClick={stayButtonAction} type={ButtonType.secondary} size={ButtonSize.medium} label={stayButtonText} />
          <Button onClick={quitButtonAction} type={ButtonType.destructive} size={ButtonSize.medium} label={quitButtonText} />
        </div>
      </footer>
    </Modal>
  )
}

ConfirmationPopup.propTypes = {
  header: PropTypes.node,
  message: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
  stayButtonText: PropTypes.string,
  stayButtonAction: PropTypes.func,
  quitButtonText: PropTypes.string,
  quitButtonAction: PropTypes.func
}

export default ConfirmationPopup
