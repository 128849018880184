import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getOrg } from '../../shared/actions'
import { getCurrentOrgId, getCurrentOrg } from '../../selectors/org'
import { getSelf } from '../../selectors/me'

const mapStateToProps = (state, ownProps) => {
  const nickname = ownProps.match.params.nickname
  const idOrg = getCurrentOrgId(state)
  const { failed } = getCurrentOrg(state)
  const me = getSelf(state)

  return {
    nickname,
    idOrg,
    failed,
    authState: me.authState
  }
}

const mapDispatchToProps = {
  getOrg
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
