import View from './view'
import { connect } from 'react-redux'
import { getCatalogSettings } from '../../selectors/org'
import { requestNewApp } from '../../shared/actions'

const mapStateToProps = (state) => {
  return {
    catalogSettings: getCatalogSettings(state)
  }
}

const mapDispatchToProps = {
  requestNewApp
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
