export const demoIdOrg = '22'
export const toriiIdOrg = '25'
const enabledForOrgs = (orgs = []) => (idOrg) => (idOrg && (orgs.includes(idOrg.toString())))

export const feature = {
  enabled: () => true,
  disabled: () => false,
  enabledForOrgs,
  enabledForTorii: enabledForOrgs([toriiIdOrg]),
  enabledForToriiAndDemo: enabledForOrgs([[demoIdOrg, toriiIdOrg]])
}
