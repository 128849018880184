import { css } from 'glamor'
import colors from '../../shared/style/colors'
import { fontSize, fontWeight } from '../../shared/style/sizes'

export const borderStyle = css({
  borderRadius: '2px',
  border: `1px solid ${colors.border}`,
  ':hover': {
    border: `1px solid ${colors.lightBlue1}`
  },
  ':focus': {
    border: `1px solid ${colors.blue}`,
    outline: 'none'
  },
  '[disabled]': {
    cursor: 'not-allowed',
    opacity: 0.5
  }
})

export const roundedBorderStyle = css({
  borderRadius: '6px'
})

export const textStyle = css({
  color: colors.darkText,
  fontSize: fontSize.small,
  fontWeight: fontWeight.normal
})

export const sizeStyle = css({
  minHeight: '30px',
  width: '100%',
  padding: '0 15px'
})

export const controlStyle = css(
  borderStyle,
  textStyle,
  sizeStyle
)
