import React from 'react'
import { css } from 'glamor'
import { Avatar, AppIcon, Icon, Button, ButtonSize, ButtonType, Spacer } from '@toriihq/design-system'
import colors from '../../shared/style/colors'
import texts from '../../shared/style/texts'
import { AppAttributesDetails } from 'torii-components'
import { safeEmailTemplating } from '../../shared/safeTemplating'
import keyBy from 'lodash/keyBy'
import get from 'lodash/get'
import compact from 'lodash/compact'
import { controlStyle } from '../form/formStyle'
import { safeWindowOpen } from '../../shared/utils'
import { VALID_FIELD_TYPES, MOBILE_BREAKPOINT } from '../../constants'
import '../../shared/style/animate.less'
import ToriiPopup from '../popups/ToriiPopupV2'
import Textarea from '../form/textarea'
import Analytics from '../../helpers/analytics'
import RequestAcessDetailsTitle from './requestAccessDetailsTitle'

const CSS = {
  details: css({
    height: '100%',
    [`@media(max-width: ${MOBILE_BREAKPOINT})`]: {
      height: '100%',
      animationDuration: '0.3s',
      animationFillMode: 'both',
      animationName: 'fadeInUp'
    }
  }),
  container: css({
    padding: '53px 27px 10px',
    width: '100%',
    marginRight: 'auto'
  }),
  infoHeader: css(texts.subheading),
  infoValue: css(texts.body, {
    color: colors.darkText,
    paddingBottom: '10px'
  }),
  appLogo: css({
    border: `1px solid ${colors.border}`,
    borderRadius: '4px'
  }),
  link: css({
    '&:focus': {
      outline: '0px !important'
    }
  }),
  oval: css({
    position: 'absolute',
    right: 27,
    top: 27,
    [`@media(max-width: ${MOBILE_BREAKPOINT})`]: {
      top: 50
    }
  }),
  popupContainer: css({
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.8)',
    animationName: 'fadeInDown',
    animationDuration: '0.3s',
    animationFillMode: 'both',
    [`@media(max-width: ${MOBILE_BREAKPOINT})`]: {
      background: 'none',
      top: window.chrome ? 30 : 0,
      animationName: 'fadeInUp'
    }
  }),
  popupBody: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderBottom: `1px solid ${colors.border}`,
    borderRadius: 5,
    padding: 20,
    background: colors.white,
    zIndex: 8,
    [`@media(max-width: ${MOBILE_BREAKPOINT})`]: {
      width: '100%',
      height: '100%',
      borderRadius: '20px 20px 0 0'
    }
  }),
  popupDescription: css(controlStyle, {
    resize: 'none',
    padding: '10px',
    marginTop: '10px',
    height: '250px',
    [`@media(min-width: ${MOBILE_BREAKPOINT})`]: {
      width: '100%'
    }
  }),
  popupTitle: css(texts.heading, {
    margin: '60px 0 8px'
  }),
  separator: css({
    width: '80%',
    border: `1px solid ${colors.lightBlue2}`
  }),
  descriptionTitle: css(texts.subheading, {
    color: colors.black,
    marginBottom: 8,
    alignSelf: 'flex-start',
    marginLeft: 5
  }),
  backButton: css({
    background: colors.transparent,
    border: 'none',
    position: 'absolute',
    top: 23,
    left: 15,
    zIndex: 9,
    display: 'none',
    fontSize: '9px',
    [`@media(max-width: ${MOBILE_BREAKPOINT})`]: {
      display: 'block'
    }
  }),
  cover: css({
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px'
  }),
  closeButton: css({
    background: colors.transparent,
    border: 'none',
    position: 'absolute',
    top: 23,
    right: 29,
    zIndex: 10,
    display: 'none',
    fontSize: '9px',
    [`@media(max-width: ${MOBILE_BREAKPOINT})`]: {
      display: 'block'
    }
  }),
  popup: {
    modal: {
      minHeight: '480px',
      width: '500px',
      maxWidth: '100%'
    }
  },
  popupContent: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  })
}

class AppAttributes extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      isTriggerWorkflowPopupOpen: false,
      accessRequestDescription: '',
      hover: false
    }

    this.renderRequestAccessButton = this.renderRequestAccessButton.bind(this)
    this.renderTriggerWorkflowPopup = this.renderTriggerWorkflowPopup.bind(this)
    this.triggerWorkflowForRequestAccess = this.triggerWorkflowForRequestAccess.bind(this)
    this.onCloseWorkflowPopup = this.onCloseWorkflowPopup.bind(this)
    this.onCloseAppDetails = this.onCloseAppDetails.bind(this)
    this.getAppFields = this.getAppFields.bind(this)
  }

  onCloseWorkflowPopup () {
    this.setState({ isTriggerWorkflowPopupOpen: false, accessRequestDescription: '' })
  }

  onCloseAppDetails () {
    const { toggleShowAppDetails, app } = this.props
    toggleShowAppDetails(app.id, app.name, false)
    this.onCloseWorkflowPopup()
  }

  renderRequestAccessButton () {
    const { catalogSettings: { requestLicenseConfig }, app: { owner, name }, self, appsResources: { users } } = this.props
    const config = keyBy(requestLicenseConfig, 'id')
    const buttonText = get(config, ['buttonName', 'value']) || 'Request access'
    const action = get(config, ['actionSelection', 'value']) || {}
    const isComposeEmail = action === 'composeEmail'
    const isOpenLink = action === 'openLink'
    const isTriggerWorkflow = action === 'triggerWorkflow'
    const onClick = () => {
      if (isComposeEmail) {
        const to = get(config, ['to', 'value']) || []
        const recipients = compact(to.map(user => user === 'appOwner' ? owner : users[user]))
        const emailCC = get(config, ['cc', 'value']) || []
        const cc = compact(emailCC.map(user => user === 'appOwner' ? owner : users[user]))

        const templateData = {
          app: { name },
          user: self,
          recipient: recipients[0]
        }
        const mailToParams = {
          to: recipients.map(recipient => recipient.email).join(),
          cc: cc.map(recipient => recipient.email).join(),
          subject: safeEmailTemplating(get(config, ['subject', 'value']), templateData),
          body: encodeURIComponent(safeEmailTemplating(get(config, ['content', 'value']), templateData))
        }

        const mailto = mailToParams && `mailto:${mailToParams.to}?${mailToParams.cc ? `cc=${mailToParams.cc}&` : ''}subject=${mailToParams.subject}&body=${mailToParams.body}`
        window.open(mailto)
      }
      if (isTriggerWorkflow) {
        Analytics.track('Open request-access-dialog', {
          'App name': name
        })

        this.setState({ isTriggerWorkflowPopupOpen: true })
      }
      if (isOpenLink) {
        safeWindowOpen(get(config, ['url', 'value']) || '')
      }
    }

    return <Spacer top='space-300'><Button size={ButtonSize.small} onClick={onClick} label={buttonText} /></Spacer>
  }

  async triggerWorkflowForRequestAccess () {
    const { app, requestAccess } = this.props
    const { accessRequestDescription } = this.state

    Analytics.track('Request access-to-an-app', {
      'App name': app.name
    })

    await requestAccess({ idApp: app.id, description: accessRequestDescription || undefined })
    setTimeout(this.onCloseWorkflowPopup, 1000)
  }

  renderTriggerWorkflowPopup () {
    const { app: { name } } = this.props
    const { isTriggerWorkflowPopupOpen, accessRequestDescription } = this.state

    if (!isTriggerWorkflowPopupOpen) {
      return null
    }

    const IS_MOBILE_VIEW = window.outerWidth <= 768

    if (!IS_MOBILE_VIEW) {
      return (<ToriiPopup
        isOpen
        onCloseAction={this.onCloseWorkflowPopup}
        styles={CSS.popup}
      >
        <ToriiPopup.Header header={`Request Access to ${name}`} />
        <ToriiPopup.Content contentAreaStyle={CSS.popupContent}>
          <span {...CSS.descriptionTitle}><RequestAcessDetailsTitle /></span>
          <Textarea {...CSS.popupDescription} value={accessRequestDescription} onChange={(e) => this.setState({ accessRequestDescription: e.target.value })} />
        </ToriiPopup.Content>
        <ToriiPopup.Footer
          mainButtonAction={this.triggerWorkflowForRequestAccess}
          mainButtonText='Request Access'
          isMainButtonDisabled={!accessRequestDescription}
          showCancelButton={false} />
      </ToriiPopup>)
    }

    return (
      <div id='popup' {...CSS.popupContainer}>
        <div {...CSS.cover} onClick={this.onCloseWorkflowPopup} />
        <button {...CSS.backButton} onClick={this.onCloseWorkflowPopup}><Icon name='ChevronLeft' /></button>
        <button {...CSS.closeButton} onClick={this.onCloseAppDetails}><Icon name='Close' /></button>
        <div {...CSS.popupBody}>
          <span {...CSS.popupTitle}>Request Access to {name}</span>
          <hr {...CSS.separator} />
          <span {...CSS.descriptionTitle}><RequestAcessDetailsTitle /></span>
          <textarea
            {...CSS.popupDescription}
            value={accessRequestDescription}
            onChange={(e) => this.setState({ accessRequestDescription: e.target.value })}
          />
          <hr {...CSS.separator} />
          <Button size='medium' onClick={this.triggerWorkflowForRequestAccess} label='Request Access' disabled={!accessRequestDescription} />
        </div>
      </div>
    )
  }

  getAppFields () {
    const { app, appsResources, catalogSettings, defaultCurrency } = this.props
    const extendedApp = { ...app }
    const fields = appsResources.fields
    extendedApp.fields = catalogSettings.appFieldsConfig ? catalogSettings.appFieldsConfig.map(systemKey => {
      let value = extendedApp[systemKey]
      if (fields[systemKey].type === VALID_FIELD_TYPES.currency) {
        value = extendedApp[systemKey] ? {
          currencySymbol: defaultCurrency,
          number: extendedApp[systemKey]
        } : {}
      } else if (systemKey === 'state') {
        value = extendedApp[systemKey] ? {
          color: fields[systemKey].options[extendedApp[systemKey]].color,
          label: fields[systemKey].options[extendedApp[systemKey]].label
        } : {}
      } else if (fields[systemKey].type === VALID_FIELD_TYPES.usersDropdownMulti) {
        value = (extendedApp[systemKey] && extendedApp[systemKey].length > 0) ? extendedApp[systemKey].filter(Boolean) : value
      }
      return {
        systemKey,
        label: fields[systemKey].name || systemKey,
        type: fields[systemKey].type,
        value
      }
    }) : []

    return extendedApp
  }

  render () {
    const { catalogSettings: { isRequestLicenseEnabled } } = this.props
    const { name, imageUrl, category, url, isMyApp, fields } = this.getAppFields()

    const iconOnClick = () => {
      window.open(url, '_blank')
    }
    return (
      <div id='detailsBox' {...CSS.details}>
        <div {...CSS.container} id='container'>
          <button {...CSS.closeButton} onClick={this.onCloseAppDetails}><Icon name='Close' /></button>
          <AppAttributesDetails>
            <AppAttributesDetails.Header useDefaultValues={false} name={name} imageUrl={imageUrl} isMyApp={isMyApp} overrideStyle={CSS.appLogo} category={category} appIcon={AppIcon} />
            <span {...CSS.oval}><Button type={ButtonType.secondary} onClick={iconOnClick} icon='ExternalLink' /></span>
            {isRequestLicenseEnabled ? this.renderRequestAccessButton() : null}
            <AppAttributesDetails.Fields
              useDefaultValues={false}
              fields={fields}
              style={{ marginTop: '30px' }}
              avatar={Avatar}
            />
          </AppAttributesDetails>
        </div>
        {this.renderTriggerWorkflowPopup()}
      </div>
    )
  }
}

export default AppAttributes
