import View from './view'
import { connect } from 'react-redux'
import { toggleMyAppsFilterAction } from '../../shared/actions'

const mapStateToProps = (state, ownProps) => {
  return {
    filterChecked: state.ui.userPreferences.showOnlyMyApps
  }
}

const mapDispatchToProps = {
  toggleMyAppsFilterAction
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
