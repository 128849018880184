import { css } from 'glamor'
import colors from '../../../shared/style/colors'
import texts from '../../../shared/style/texts'

export const defaultModalWidth = '500px'
export const smallDeviceWidth = '610px'

export const CloseButton = css({
  margin: '16px 16px 0',
  zIndex: 111,
  ':focus': {
    outline: 'none !important'
  }
})

export const Modal = css({
  zIndex: 5,
  border: `1px solid ${colors.grey3}`,
  borderRadius: '4px',
  outline: 'none',
  boxShadow: '0 16px 23px 0 rgba(0,0,0,0.24)',
  background: colors.white,
  padding: '0px 0px',
  maxWidth: '1200px',
  maxHeight: '700px',
  width: defaultModalWidth
})

export const SmallScreenModal = css({
  border: `1px solid ${colors.grey3}`,
  borderRadius: '4px',
  outline: 'none',
  boxShadow: '0 16px 23px 0 rgba(0,0,0,0.24)',
  background: colors.white,
  padding: '0px 0px',
  maxWidth: '90vw',
  maxHeight: '700px',
  width: defaultModalWidth
})

export const HeaderContainer = css({
  borderBottom: `1px solid ${colors.border}`,
  padding: '60px 30px 20px',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '105px'
})

export const Header = css(texts.headers.regular, {
  display: 'flex'
})

export const SubHeader = css(texts.body, {
  padding: '4px 0px 0px',
  color: texts.grey2,
  display: 'flex'
})

export const CustomHeader = css(texts.body, {
  display: 'flex'
})

export const ContentArea = (headerAndFooterHeight) => css({
  padding: '30px 30px',
  flexGrow: 1,
  overflowY: 'auto',
  width: '100%',
  [`@media(max-height: 700px)`]: {
    maxHeight: `calc(100vh - ${headerAndFooterHeight + 26}px)`
  },
  [`@media(min-height: 700px)`]: {
    maxHeight: `calc(700px - ${headerAndFooterHeight}px)`
  }
})

export const FormArea = (headerAndFooterHeight) => css({
  display: 'flex',
  flexDirection: 'column',
  gap: '30px'
}, ContentArea(headerAndFooterHeight))

export const Footer = css({
  borderTop: `1px solid ${colors.border}`,
  minHeight: '75px',
  maxHeight: '75px',
  padding: '0 30px',
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 1,
  justifyContent: 'flex-end',
  alignItems: 'center',
  flexWrap: 'wrap',
  [`@media(max-width: ${smallDeviceWidth})`]: {
    justifyContent: 'center',
    minHeight: '90px',
    maxHeight: '90px'
  }
})

export const Buttons = css({
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
  [`@media(max-width: ${smallDeviceWidth})`]: {
    margin: '10px 0',
    width: '100%'
  }
})
