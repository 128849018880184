import { createSelector } from 'reselect'
import identity from 'lodash/identity'
import flatten from 'lodash/flatten'
import Fuse from 'fuse.js'
import { getCatalogSettings } from './org'
import { getUserPreferences } from './ui'
import { VALID_FIELD_TYPES } from '../constants'
import { keyBy } from 'lodash'

export const isAppsLoading = createSelector(
  state => state.apps.loadingApps,
  identity
)

export const getAppsInCatalog = createSelector(
  state => state.apps.apps,
  identity
)

export const getUsedAppsByIds = createSelector(
  [getAppsInCatalog],
  apps => keyBy(apps, 'id')
)

export const isUserAppsLoading = createSelector(
  state => state.apps.loadingUserApps,
  identity
)

export const getUserAppsIds = createSelector(
  state => state.apps.userAppsIds,
  identity
)

export const getAppsResources = createSelector(
  state => state.apps.resources,
  identity
)

export const deprecatedGetApps = createSelector(
  state => state.apps.appsFromAppsDB,
  identity
)

export const deprecatedGetAppsByIds = createSelector(
  [deprecatedGetApps],
  apps => keyBy(apps, 'id')
)

export const getSearchFn = createSelector(
  [getAppsInCatalog, getAppsResources, getCatalogSettings],
  (apps, resources, settings) => {
    const extendedKeys = settings.appFieldsConfig && settings.appFieldsConfig.map(systemKey => {
      if (resources.fields[systemKey] && resources.fields[systemKey].type === VALID_FIELD_TYPES.usersDropdown) {
        return [`${systemKey}.firstName`, `${systemKey}.lastName`, `${systemKey}.email`]
      }
      return systemKey
    })
    const options = {
      keys: ['name', 'category'].concat(flatten(extendedKeys)),
      minMatchCharLength: 2,
      shouldSort: true,
      threshold: 0.1,
      distance: 1000
    }

    const fuse = new Fuse(apps, options)
    return fuse.search.bind(fuse)
  }
)

export const getCategoryFilteredApps = createSelector(
  [getAppsInCatalog, getUserPreferences],
  (apps, preferences) => {
    const { showCategories, showOnlyMyApps } = preferences
    if (!showCategories.length && !showOnlyMyApps) {
      return apps
    }

    let filteredApps = apps
    if (showCategories.length) {
      filteredApps = filteredApps.filter(app => showCategories.includes(app.category))
    }
    if (showOnlyMyApps) {
      filteredApps = filteredApps.filter(app => app.isMyApp)
    }

    return filteredApps
  }
)
