import ga from './ga'
import segment from './segment'
import sentry from './sentry'

let currentMe
const identify = me => {
  if (!me || !me.id) {
    return
  }
  if (currentMe && currentMe.id === me.id) {
    return
  }
  currentMe = me
  const name = me.firstName + ' ' + me.lastName
  const role = me.role

  let options = {
    integrations: {
      'All': true,
      'Intercom': false
    }
  }

  if (me.isAdmin) {
    options = {
      integrations: {
        'All': true,
        'Intercom': {
          user_hash: me.intercomUserHash
        }
      }
    }
  }

  segment && segment.identify(me.id, {
    createdAt: new Date(me.creationTime),
    email: me.email,
    name: name,
    firstName: me.firstName,
    lastName: me.lastName,
    company: {
      id: me.idOrg,
      name: me.org && me.org.companyName
    },
    isAdmin: me.isAdmin,
    Role: role
  }, options)

  segment && segment.group(me.idOrg, {
    name: me.org && me.org.companyName
  })

  sentry && sentry.identify(me)
}

const pageView = (pathname) => {
  segment && segment.page()
  ga && ga.pageview(pathname)
}

const track = (event, props) => {
  segment && segment.track(event, props)
}

const alias = (id) => {
  segment && segment.alias(id)
}

const analytics = {
  identify,
  pageView,
  track,
  alias
}

export default analytics
