import {
  GET_ORG,
  GET_ORG_CATALOG_SETTINGS, GET_ORG_SUPPORTED_FEATURES
} from '../constants'

const initialState = {
  loading: false,
  failed: false,
  catalogSettings: {},
  supportedFeatures: {}
}

const orgReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_ORG}_PENDING`: {
      return {
        ...state,
        loading: true,
        failed: false
      }
    }
    case `${GET_ORG}_FAILED`: {
      return {
        ...state,
        loading: false,
        failed: true
      }
    }
    case `${GET_ORG}_RESPONSE`: {
      const { org } = action.payload
      return {
        ...state,
        ...org,
        loading: false,
        failed: false
      }
    }
    case `${GET_ORG_CATALOG_SETTINGS}_PENDING`: {
      return {
        ...state,
        catalogSettings: {
          ...state.catalogSettings,
          loading: true
        }
      }
    }
    case `${GET_ORG_CATALOG_SETTINGS}_FAILED`: {
      return {
        ...state,
        catalogSettings: {
          ...state.catalogSettings,
          loading: false
        }
      }
    }
    case `${GET_ORG_CATALOG_SETTINGS}_RESPONSE`: {
      const { catalogSettings } = action.payload
      return {
        ...state,
        catalogSettings: {
          ...state.catalogSettings,
          ...catalogSettings,
          loading: false
        }
      }
    }
    case `${GET_ORG_SUPPORTED_FEATURES}_RESPONSE`: {
      const { supportedFeatures } = action.payload
      return {
        ...state,
        supportedFeatures
      }
    }
    default: {
      return state
    }
  }
}

export default orgReducer
