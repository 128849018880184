import View from './view'
import { connect } from 'react-redux'

const mapStateToProps = state => {
  const { searchMatchingAppThatIsNotInCatalog } = state.similarApps || {}
  return {
    searchMatchingAppThatIsNotInCatalog
  }
}
export default connect(mapStateToProps)(View)
