import React from 'react'
import PropTypes from 'prop-types'
import Analytics from '../../helpers/analytics'
import { Route } from 'react-router-dom'
import { AUTH_STATE } from '../../constants'
import config from '../../config'
import Layout from '../layout'

class AuthenticatedRoute extends React.Component {
  constructor (props) {
    super(props)
    this.renderMatch = this.renderMatch.bind(this)
    this.renderUnauthorized = this.renderUnauthorized.bind(this)
  }

  async componentDidMount () {
    const { authState } = this.props
    if (authState === 'BEFORE_AUTH' || authState === 'NOT_AUTHENTICATED') {
      const me = await this.props.getMe()
      Analytics.identify(me)
    }
  }

  renderMatch () {
    const { component: Component, withLayout = true } = this.props

    return withLayout ? <Layout><Component /></Layout> : <Component />
  }

  renderUnauthorized () {
    const { unauthorizedComponent: Component } = this.props
    return <Component />
  }

  render () {
    const { adminOnly, authState, me, path } = this.props

    if (authState === AUTH_STATE.AUTHENTICATED || me.id) {
      if ((adminOnly && !me.isToriiAdmin && !me.isAdmin) ||
        (me.org && me.org.isDisabled) ||
        (me.org && !me.org.isCatalogEnabledForMembers && !me.isAdmin)) {
        return <Route path={path} render={this.renderUnauthorized} />
      } else {
        return <Route path={path} render={this.renderMatch} />
      }
    }

    if (authState === AUTH_STATE.NOT_AUTHENTICATED) {
      const returnToUrl = window.location.href
      const toLoginFullUrl = `${config.appBaseUrl}/login?next=${encodeURIComponent(returnToUrl)}`
      window.location = toLoginFullUrl
      return null
    }

    return null
  }
}

AuthenticatedRoute.propTypes = {
  adminOnly: PropTypes.bool,
  unauthorizedComponent: PropTypes.func,
  me: PropTypes.object,
  location: PropTypes.object,
  path: PropTypes.string,
  authState: PropTypes.oneOf(Object.keys(AUTH_STATE))
}

export default AuthenticatedRoute
