import Analytics from '../helpers/analytics'
import { TOGGLE_FILTER_CATEGORY, TOGGLE_FILTER_MY_APPS, TOGGLE_SHOW_APP_DETAILS } from '../constants'

const analyticsMiddleWare = ({ getState }) => next => action => {
  const handlers = {
    [TOGGLE_FILTER_CATEGORY]: ({ category, shouldAdd }) => {
      Analytics.track('Toggle category filter', {
        'Category name': category,
        'Action name': shouldAdd ? 'checked' : 'unchecked'
      })
    },
    [TOGGLE_FILTER_MY_APPS]: ({ shouldFilter }) => {
      Analytics.track('Toggle my apps filter', {
        'Action name': shouldFilter ? 'checked' : 'unchecked'
      })
    },
    [TOGGLE_SHOW_APP_DETAILS]: ({ appName, selected }) => {
      Analytics.track('Toggle show app details', {
        'App name': appName,
        'Action name': selected ? 'close' : 'open'
      })
    }
  }
  handlers[action.type] && handlers[action.type]({ ...action.meta, ...action.payload }, getState)
  next(action)
}

export default analyticsMiddleWare
