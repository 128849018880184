import { createSelector } from 'reselect'
import get from 'lodash/get'
import identity from 'lodash/identity'

export const getUserPreferences = createSelector(
  state => get(state, ['ui', 'userPreferences'], {}),
  identity
)

export const getSearchQuery = createSelector(
  state => get(state, ['ui', 'search'], {}),
  identity
)
