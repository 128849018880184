import { createSelector } from 'reselect'
import identity from 'lodash/identity'

export const getCatalogSettings = createSelector(
  state => state.org.catalogSettings,
  identity
)

export const getCurrentOrg = createSelector(
  state => state.org,
  identity
)

export const getCurrentOrgId = createSelector(
  getCurrentOrg,
  org => org.id
)
