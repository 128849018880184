export const categoryColors = {
  Operations: '#FFD600',
  'Sales & Marketing': '#FFA31E',
  'Developer Tools': '#A200FF',
  Design: '#7400FF',
  'Project Management': '#1A31AE',
  'Customer Success': '#0054FF',
  'Human Resource': '#7AACF4',
  'IT & Security': '#0DD1FF',
  Finance: '#0DE6FF',
  Productivity: '#0DFFE3',
  'Analytics & BI': '#06F59A',
  Other: '#7189B8'
}

export const baseColors = {
  transparent: 'transparent',
  white: '#FFFFFF',
  blue: '#0054FF',
  darkBlue: '#00154E',
  darkBlue2: '#7189B8',
  darkBlue3: '#0039AC',
  cyan: '#0DD1FF',
  navy: '#1A31AE',
  black: '#333333',
  grey1: '#757575',
  grey2: '#A5A5A5',
  grey3: '#EBEBEB',
  grey4: '#F9F9F9',
  lightBlue1: '#7AACF4',
  lightBlue2: '#E6EBF4',
  lightBlue3: '#F7FAFF',
  lightBlue4: '#0DD1FF',
  green: '#1BE683',
  turquoise: '#00E0CF',
  red: '#F53059',
  orange: '#FFA31E',
  orange2: '#F56B30',
  purple: '#A200FF',
  darkGreen: '#228B22',
  yellow: '#D2AA16'
}

export const colors = {
  ...baseColors,
  success: baseColors.green,
  error: baseColors.red,
  background: baseColors.lightBlue3,
  border: baseColors.lightBlue2,
  darkBorder: baseColors.lightBlue1,
  text: baseColors.grey1,
  darkText: baseColors.black
}

export default colors
