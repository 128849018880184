import View from './view'
import { connect } from 'react-redux'
import { getSelf } from '../../selectors/me'
import { getMe } from '../../shared/actions'
import { withRouter } from 'react-router-dom'

const mapStateToProps = state => {
  const me = getSelf(state)
  return {
    authState: me.authState,
    me
  }
}

const mapDispatchToProps = {
  getMe
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
