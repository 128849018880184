import React, { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import ApplicationsPage from '../../pages/appsCatalog'
import AuthenticatedRoute from '../authenticatedRoute'
import TeamFallbackRoute from '../teamFallbackRoute'
import NoPermissionsPage from '../../pages/noPermissions'

class Router extends Component {
  render () {
    return (
      <div>
        <Switch>
          <AuthenticatedRoute path='/:nickname' component={ApplicationsPage} unauthorizedComponent={NoPermissionsPage} />
          <AuthenticatedRoute path='/:nickname?' withLayout={false} component={TeamFallbackRoute} unauthorizedComponent={NoPermissionsPage} />
          <Route exact path='/' render={() => <Redirect to='/' />} />
        </Switch>
      </div>
    )
  }
}

export default Router
