import React, { useState } from 'react'
import { css } from 'glamor'
import { safeWindowOpen } from '../../shared/utils'
import { MOBILE_BREAKPOINT } from '../../constants'
import { Button, ButtonType, Spacer } from '@toriihq/design-system'
import ToriiPopup from '../popups/ToriiPopupV2'
import SelectApps from '../selectApps'
import { fontWeight } from '../../shared/style/sizes'
import Confirmation from '../confirmation'
import successImage from './images/success.svg'
import { defaultModalWidth } from '../popups/ToriiPopupV2/style'

const CSS = {
  wrapper: css({
    position: 'absolute',
    right: 0,
    marginTop: 35,
    [`@media(max-width: ${MOBILE_BREAKPOINT})`]: {
      padding: 0,
      position: 'relative',
      marginTop: 6
    }
  }),
  contentTitle: css({
    fontWeight: fontWeight.bold
  }),
  appSelector: css({
    margin: 0
  }),
  confirmationPopup: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  })
}

const OPEN_LINK_ACTION = 'openLink'
const TRIGGER_WORKFLOW_ACTION = 'triggerWorkflow'

const RequestNewAppButton = ({ catalogSettings, requestNewApp }) => {
  const [isRequestNewAppPopupOpen, setIsRequestNewAppPopupOpen] = useState(false)
  const [isConfirmationPopupOpen, setIsConfirmationPopupOpen] = useState(false)
  const [requestedIdApp, setRequestedIdApp] = useState(null)

  const onCloseRequestNewAppPopup = () => {
    setIsRequestNewAppPopupOpen(false)
    setRequestedIdApp(null)
  }

  const triggerWorkflowForRequestNewApp = async () => {
    await requestNewApp({ idApp: requestedIdApp })
    setIsConfirmationPopupOpen(true)
    setIsRequestNewAppPopupOpen(false)
  }

  const requestNewAppPopup = () => {
    return <ToriiPopup isOpen onCloseAction={onCloseRequestNewAppPopup}>
      <ToriiPopup.Header header={'Request a new application'} />
      <ToriiPopup.Content>
        <Spacer bottom={'space-100'}>
          <span {...CSS.contentTitle}>Application</span>
        </Spacer><SelectApps
          placeholder='Select app'
          className={CSS.appSelector}
          disableExistsApps
          alreadyExistsBadgeText='Already available'
          hiddenBadgeText='Request blocked'
          disableHiddenApps
          onChange={(app, searchValue) => {
            setRequestedIdApp(app.id)
          }} />
      </ToriiPopup.Content>
      <ToriiPopup.Footer
        mainButtonAction={triggerWorkflowForRequestNewApp}
        mainButtonText='Submit Request'
        cancelButtonText='Cancel'
        isMainButtonDisabled={!requestedIdApp}
      />
    </ToriiPopup>
  }

  const requestNewAppConfirmation = () => {
    return <Confirmation
      modalWidth={defaultModalWidth}
      isOpen={isConfirmationPopupOpen}
      header={`Request a new application`}
      text={<div {...CSS.confirmationPopup}>
        <span>Your request has been received! <br />Our team will be in touch with you with regards to next steps.</span>
        <img src={successImage} alt='Success' />
      </div>}
      confirmText='Close'
      confirm={() => setIsConfirmationPopupOpen(false)}
      close={() => setIsConfirmationPopupOpen(false)}
      showCancelButton={false}
    />
  }

  const onClick = () => {
    const actionSelection = catalogSettings.requestNewAppConfig.find(config => config.type === 'actionSelection').value
    if (actionSelection === OPEN_LINK_ACTION) {
      const url = catalogSettings.requestNewAppConfig.find(config => config.type === 'url').value
      safeWindowOpen(url)
    } else if (actionSelection === TRIGGER_WORKFLOW_ACTION) {
      setIsRequestNewAppPopupOpen(true)
    }
  }

  if (!catalogSettings.isRequestNewAppEnabled) {
    return null
  }

  const buttonText = catalogSettings.requestNewAppConfig.find(config => config.type === 'buttonText').value || 'Request new application'

  return <>
    <div {...CSS.wrapper}><Button type={ButtonType.secondary} onClick={onClick} isError={false} label={buttonText} /></div>
    {isRequestNewAppPopupOpen && requestNewAppPopup()}
    {isConfirmationPopupOpen && requestNewAppConfirmation()}
  </>
}

export default RequestNewAppButton
