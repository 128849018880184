import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import range from 'lodash/range'
import groupBy from 'lodash/groupBy'
import keyBy from 'lodash/keyBy'
import {
  getAppsFromServer,
  getOrg,
  getOrgCatalogSettings,
  getUserAppsFromServer,
  toggleShowAppDetails,
  getSupportedFeatures
} from '../../shared/actions'
import { getAppsInCatalog, getUserAppsIds, isAppsLoading, isUserAppsLoading, getCategoryFilteredApps, getSearchFn } from '../../selectors/apps'
import { getSearchQuery, getUserPreferences } from '../../selectors/ui'
import { getSelf } from '../../selectors/me'
import { getCatalogSettings, getCurrentOrg } from '../../selectors/org'

const mapStateToProps = (state) => {
  const idUser = getSelf(state).id
  const catalogSettings = getCatalogSettings(state)
  const { companyName, logoUrl, isWhiteLabel, id: idOrg } = getCurrentOrg(state) || {}
  const apps = getAppsInCatalog(state)
  const loading = isAppsLoading(state) && isUserAppsLoading(state)
  const loadingApps = range(18).map(index => ({ id: index, name: '' }))
  let appsByIdApp = {}
  if (apps && apps.length > 0) {
    appsByIdApp = keyBy(apps, 'id')
    const userAppsIds = getUserAppsIds(state)
    userAppsIds.forEach(appId => appsByIdApp[appId] && (appsByIdApp[appId].isMyApp = true))
  }

  const { showCategories } = getUserPreferences(state)
  const categories = groupBy(apps, 'category')
  const checkboxes = Object.keys(categories).sort().map(name => ({ name, show: showCategories.includes(name) }))

  let filteredApps = getCategoryFilteredApps(state)
  const search = getSearchQuery(state)
  if (search) {
    const appsMatchingSearch = getSearchFn(state)(search).map(app => app.id)
    filteredApps = filteredApps.filter(app => appsMatchingSearch.includes(app.id))
  }

  const { similarApps, searchMatchingAppThatIsNotInCatalog } = state.similarApps || {}
  const similarAppsInCatalog = (similarApps || []).map(sApp => appsByIdApp[sApp.id])

  const selectedApp = (similarAppsInCatalog || []).concat(filteredApps).find(app => app.id === state.ui.showDetailsForAppId)

  return {
    user: getSelf(state),
    idOrg,
    idUser,
    apps: loading && isEmpty(apps) ? loadingApps : filteredApps,
    checkboxes,
    search,
    searchLoading: state.ui.searchLoading,
    companyName,
    logoUrl,
    isWhiteLabel,
    loading,
    catalogSettings,
    selectedApp,
    similarAppsInCatalog,
    searchMatchingAppThatIsNotInCatalog
  }
}

const mapDispatchToProps = {
  getAppsFromServer,
  getUserAppsFromServer,
  getOrg,
  getOrgCatalogSettings,
  toggleShowAppDetails,
  getSupportedFeatures
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
