export const padding = {
  large: '13px 24px',
  medium: '7px 14px',
  small: '4px 14px'
}

export const margin = {
  large: '30px',
  medium: '15px',
  small: '5px'
}

export const fontSize = {
  large: '18px',
  medium: '14px',
  small: '12px',
  extraSmall: '9px'
}

export const fontWeight = {
  light: 300,
  normal: 400,
  semiBold: 600,
  bold: 700
}

export const zIndex = {
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 1000000
}
