import React from 'react'
import { css } from 'glamor'
import colors from '../../shared/style/colors'
import { fontSize } from '../../shared/style/sizes'
import debounce from 'lodash/debounce'
import Analytics from '../../helpers/analytics'

import Magnifier from '@media/magnifier.svg'
import Spinner from '@media/spinner.gif'

const CSS = {
  main: css({
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  }),
  input: css({
    height: '50px',
    width: '100%',
    border: `1px solid ${colors.border}`,
    borderRadius: '6px',
    margin: '10px 0',
    paddingLeft: '45px',
    fontSize: fontSize.medium,
    color: colors.text,
    transition: 'border .2s',
    ':focus': {
      outline: 'none',
      border: `1px solid ${colors.blue}`
    }
  }),
  magnifier: css({
    position: 'absolute',
    left: '15px',
    top: 0,
    bottom: 0,
    margin: 'auto'
  }),
  spinner: css({
    position: 'absolute',
    right: '10px'
  })
}

const onSearchStopped = debounce(async ({ value, idOrg, supportedFeatures, getSimilarApps, setSearchLoading }) => {
  if (!value) {
    return
  }

  await getSimilarApps({ appName: value, idOrg })

  setSearchLoading(false)
  Analytics.track('Search Table', {
    'Table': 'Application Catalog',
    'Term': value
  })
}, 1000)

const Search = ({ idOrg, query, searchLoading, supportedFeatures, changeSearch, getSimilarApps, setSearchLoading }) => {
  const onChange = (e) => {
    const value = e.target.value
    changeSearch && changeSearch(value)
    onSearchStopped({ value, idOrg, getSimilarApps, setSearchLoading, supportedFeatures })
  }

  return (
    <div {...CSS.main}>
      <input type='text' placeholder='Search apps by name, category, owner, tags and more...' onChange={onChange} value={query} {...CSS.input} />
      <img src={Magnifier} width={20} height={20} alt='magnifier' {...CSS.magnifier} />
      {searchLoading ? <img src={Spinner} width={26} height={26} alt='spinner' {...CSS.spinner} /> : null}
    </div>
  )
}

export default Search
