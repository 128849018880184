import React, { createContext, useCallback, useRef, useState } from 'react'

export const ToriiPopupContext = createContext({ onClose: () => {}, isConfirmCloseOpen: false, setIsConfirmCloseOpen: () => {} })

export const ToriiPopupProvider = ({ children, confirmClose, onCloseAction }) => {
  const [isConfirmCloseOpen, setIsConfirmCloseOpen] = useState(false)
  const closeAction = useCallback(() => onCloseAction(), [onCloseAction])
  const [scopes, setScopes] = useState([])
  const [initialValues, setInitialValues] = useState({})
  const submitAction = useRef(() => {})

  const onClose = () => {
    if (confirmClose) {
      setIsConfirmCloseOpen(true)
    } else {
      closeAction()
    }
  }

  return (
    <ToriiPopupContext.Provider value={{ onClose, isConfirmCloseOpen, setIsConfirmCloseOpen, submitAction, scopes, setScopes, initialValues, setInitialValues }}>
      {children}
    </ToriiPopupContext.Provider>
  )
}
