import React from 'react'
import { css } from 'glamor'
import { Redirect } from 'react-router-dom'
import { AUTH_STATE, MOBILE_BREAKPOINT } from '../../constants'
import { fontSize } from '../../shared/style/sizes'
import config from '../../config'
import Confirmation from '../confirmation'

const CSS = {
  layout: css({
    display: 'flex',
    minHeight: '100vh',
    [`@media(max-width: ${MOBILE_BREAKPOINT})`]: {
      display: 'block'
    }
  })
}

class Layout extends React.Component {
  componentDidMount () {
    this.fetchInitialData()
  }

  componentDidUpdate (prevProps) {
    const { nickname } = this.props
    const orgChanged = (nickname && (nickname !== prevProps.nickname))
    if (orgChanged) {
      this.fetchInitialData()
    }
  }

  async fetchInitialData () {
    const { nickname, getOrg } = this.props
    if (!nickname) {
      return null
    }

    await getOrg({ nickname })
  }

  goToLoginPage () {
    window.location.href = `${config.appBaseUrl}/login?next=${encodeURIComponent(window.location.href)}`
  }

  render () {
    const { authState, failed, idOrg, children } = this.props

    if (failed) {
      return <Redirect to='/' />
    }

    return (
      <div {...CSS.layout}>
        <Confirmation
          isOpen={(authState === AUTH_STATE.NO_LONGER_AUTHENTICATED)}
          header={'Time to login again'}
          text={<div style={{ fontSize: fontSize.medium }}>For security reasons, you have been logged out due to inactivity.</div>}
          confirmText='Go to Login page'
          confirm={this.goToLoginPage}
          close={this.goToLoginPage}
        />
        {idOrg && children}
      </div>
    )
  }
}

Layout.defaultProps = {
  children: []
}

export default Layout
