import {
  GET_APPS,
  GET_ME,
  GET_ORG,
  GET_ORG_CATALOG_SETTINGS,
  GET_ORG_SUPPORTED_FEATURES,
  TOGGLE_FILTER_CATEGORY,
  CHANGE_SEARCH,
  GET_USER_APPS,
  TOGGLE_FILTER_MY_APPS,
  TOGGLE_SHOW_APP_DETAILS,
  REQUEST_ACCESS,
  GET_SIMILAR_APPS,
  SET_SEARCH_LOADING,
  SEARCH_APPS,
  GET_ALL_APPS_FROM_APPS_DB
} from '../constants'
import send from './redux-fetch'
import { isUndefined, omitBy } from 'lodash'

export const getAppsFromServer = ({ idOrg }) => {
  return send(GET_APPS, {
    url: `/api/orgs/${idOrg}/catalog/apps`
  })
}

export const toggleFilterByCategory = (category, shouldAdd) => ({
  type: TOGGLE_FILTER_CATEGORY,
  payload: { category, shouldAdd },
  meta: {}
})

export const toggleMyAppsFilterAction = (shouldFilter) => ({
  type: TOGGLE_FILTER_MY_APPS,
  payload: { shouldFilter },
  meta: {}
})

export const toggleShowAppDetails = (idApp, appName, selected) => ({
  type: TOGGLE_SHOW_APP_DETAILS,
  payload: { idApp, appName, selected },
  meta: {}
})

export const getMe = () =>
  send(GET_ME, {
    url: '/api/users/my'
  })

export const getUserAppsFromServer = ({ idOrg, idUser }) =>
  send(GET_USER_APPS, {
    url: `/api/orgs/${idOrg}/users/${idUser}/apps`
  })

export const getOrg = ({ nickname }) =>
  send(GET_ORG, {
    url: `/api/orgs/${nickname}`
  })

export const changeSearch = search => ({
  type: CHANGE_SEARCH,
  payload: { search }
})

export const getOrgCatalogSettings = ({ idOrg }) =>
  send(GET_ORG_CATALOG_SETTINGS, {
    url: `/api/orgs/${idOrg}/catalogSettings`
  })

export const getSupportedFeatures = ({ idOrg }) =>
  send(GET_ORG_SUPPORTED_FEATURES, {
    url: `/api/orgs/${idOrg}/supportedFeatures`
  })

export const requestAccess = ({ idApp, description }) =>
  send(REQUEST_ACCESS, {
    url: `/api/apps/${idApp}/requestAccess`,
    method: 'POST',
    body: {
      description
    }
  })

export const requestNewApp = ({ idApp }) =>
  send(REQUEST_ACCESS, {
    url: `/api/apps/${idApp}/requestNewApp`,
    method: 'POST'
  })

export const getSimilarApps = ({ appName, idOrg }) =>
  send(GET_SIMILAR_APPS, {
    url: `/api/orgs/${idOrg}/catalog/apps/similar`,
    query: {
      appName
    }
  })

export const setSearchLoading = isLoading => ({
  type: SET_SEARCH_LOADING,
  payload: { isLoading }
})

export const searchApps = ({ idOrg, q, limit, exactAppName }) => {
  return send(SEARCH_APPS, {
    url: `/api/apps/orgs/${idOrg}`,
    query: omitBy({
      q: (q || undefined),
      limit,
      exactAppName
    }, isUndefined)
  })
}

export const getAllAppsFromAppsDB = ({ idOrg }) => {
  return send(GET_ALL_APPS_FROM_APPS_DB, {
    url: `/api/orgs/${idOrg}/apps/usage`
  })
}
