import React from 'react'
import { css } from 'glamor'
import { AppIcon, Spacer } from '@toriihq/design-system'
import colors from '../../shared/style/colors'
import texts from '../../shared/style/texts'
import MatchingAppIcon from '../../shared/images/matching-app.svg'

const CSS = {
  container: css({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '90px',
    width: '255px',
    border: `1px solid ${colors.grey3}`,
    borderRadius: '4px',
    backgroundColor: colors.white,
    marginBottom: '10px',
    padding: '20px',
    textAlign: 'left',
    opacity: 0.6
  }),
  matchingAppIcon: css({
    position: 'absolute',
    right: 0,
    top: 0,
    transform: 'translate(15px, -20px)'
  })
}
const SearchMatchingAppThatIsNotInCatalog = ({ searchMatchingAppThatIsNotInCatalog: app }) => {
  return (
    <div {...CSS.container}>
      <img src={MatchingAppIcon} alt='found-app' width={44} height={44} {...CSS.matchingAppIcon} />
      <Spacer right='space-300'>
        <AppIcon size='large' appName={app.name} appImageUrl={app.imageUrl} />
      </Spacer>
      <div {...CSS.appName}>
        <div {...texts.heading}>{app.name}</div>
        <span {...texts.body}>Not available</span>
      </div>
    </div>
  )
}

export default SearchMatchingAppThatIsNotInCatalog
