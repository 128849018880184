import universalGA from 'universal-ga'
import config from '../config'

let ga = null
if (config.googleAnalytics) {
  ga = universalGA
  ga.initialize(config.googleAnalytics)
}

export default ga
