import { combineReducers } from 'redux'
import appsReducer from './apps'
import meReducer from './me'
import orgReducer from './org'
import uiReducer from './ui'
import similarAppsReducer from './similarApps'

const rootReducer = combineReducers({
  apps: appsReducer,
  me: meReducer,
  org: orgReducer,
  ui: uiReducer,
  similarApps: similarAppsReducer
})

export default rootReducer
