import React, { Component } from 'react'
import { css } from 'glamor'
import PropTypes from 'prop-types'
import isUndefined from 'lodash/isUndefined'
import debouncePromise from 'debounce-promise'
import { AppIcon, AsyncSelect, Spacer, TextBadge } from '@toriihq/design-system'
import { DisabledApp, Name } from './styles'
import { DEFAULT_SEARCH_APPS_LIMIT } from '../../../src/constants'

const CSS = {
  app: css({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%'
  }),
  appIconAndName: css({
    marginRight: '8px',
    display: 'flex',
    alignItems: 'center',
    flex: 1
  })
}

const SelectApp = ({ item, hideBadge, alreadyExistsBadgeText, hiddenBadgeText }) => {
  const displayBadge = (item.badge || item.exists || item.hidden) && !hideBadge
  const badge = displayBadge ? item.badge || <TextBadge size='Small'>{ (item.exists ? alreadyExistsBadgeText : item.hidden ? hiddenBadgeText : null)}</TextBadge> : null

  return <div {...CSS.app}>
    <div {...CSS.appIconAndName}>
      <Spacer right={'space-100'}>
        {(item.exists || item.hidden)
          ? <DisabledApp appImageUrl={item.imageUrl}>
            <AppIcon appName={item.name} appImageUrl={item.imageUrl} size='small' />
          </DisabledApp>
          : <AppIcon appName={item.name} appImageUrl={item.imageUrl} size='small' />
        }
      </Spacer>
      <Name>{item.name}</Name>
    </div>
    {badge}
  </div>
}

class SelectApps extends Component {
  state = {
    selectedApp: null
  }

  onChange = async (selectedApp) => {
    const { onChange } = this.props
    const { inputValue } = this.state
    this.setState({ selectedApp })
    onChange && onChange(selectedApp, inputValue)
  }

  fetchData = debouncePromise(async (searchValue) => {
    const { idOrg, searchApps, usedAppsById, disableExistsApps, disableHiddenApps, specialOption, includeAllApps, transformApps, limit = DEFAULT_SEARCH_APPS_LIMIT, hasApplicationsTakeActionScope } = this.props
    const response = await searchApps({ idOrg, q: searchValue, limit, includeAllApps })
    const isCustomAppOption = specialOption?.value === 'customApp'
    const canRenderSpecialOption = specialOption && ((isCustomAppOption && hasApplicationsTakeActionScope) || !isCustomAppOption)

    if (canRenderSpecialOption) {
      specialOption.isSpecial = true
    }

    return transformApps ? transformApps(response.apps) : response.apps.map(app => {
      const exists = disableExistsApps && !!usedAppsById[app.id]
      const hidden = disableHiddenApps && !!app.isHidden
      return { ...app, exists, hidden, disabled: exists || hidden, value: app.id, label: app.name }
    }).concat(canRenderSpecialOption ? specialOption : [])
  }, 500)

  getOptions = async (searchValue) => {
    const { initialOptions } = this.props
    if (!searchValue) {
      return initialOptions ? { options: initialOptions } : { options: [] }
    }

    return this.fetchData(searchValue)
  }

  renderApp = (item, hideBadge) => {
    const { alreadyExistsBadgeText = 'Already detected', hiddenBadgeText = 'Hidden' } = this.props
    if (item.isSpecial && item.render) {
      const { inputValue } = this.state
      return item.render(inputValue)
    }

    return <SelectApp item={item} hideBadge={hideBadge} alreadyExistsBadgeText={alreadyExistsBadgeText} hiddenBadgeText={hiddenBadgeText} />
  }

  onInputChange = (value) => {
    value && this.setState({ inputValue: value })
    return value
  }

  render () {
    const { value, placeholder } = this.props
    const { selectedApp } = this.state

    return (
      <AsyncSelect
        ref={select => { this.select = select }}
        optionRenderer={({ data: item }) => this.renderApp(item, false)}
        valueRenderer={({ data: item }) => this.renderApp(item, true)}
        valueKey='id'
        labelKey='name'
        placeholder={placeholder}
        backspaceRemovesValue={false}
        isClearable={false}
        autoFocus
        openMenuOnFocus
        noOptionsMessage={({ inputValue }) => inputValue ? 'No apps found' : 'Type to search...'}
        loadOptions={this.getOptions}
        cacheOptions={false}
        loadingMessage={() => 'Loading...'}
        onInputChange={this.onInputChange}
        value={isUndefined(value) ? selectedApp : value}
        onChange={this.onChange}
        isOptionDisabled={option => option.disabled}
      />
    )
  }
}

SelectApps.propTypes = {
  placeholder: PropTypes.string,
  includeAllApps: PropTypes.bool
}

SelectApps.defaultProps = {
  placeholder: 'Select App...',
  includeAllApps: true
}

export default SelectApps
