import React from 'react'
import { Link, Button, ButtonType, ButtonSize } from '@toriihq/design-system'
import { css } from 'glamor'
import texts from '../../shared/style/texts'
import capitalize from 'lodash/capitalize'
import config from '../../config'
import ToriiLogo from '@media/torii_logo.svg'

const CSS = {
  main: css({
    marginTop: '100px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'center'
  }),
  logo: css({
    marginBottom: '25px'
  }),
  title: css(texts.headers.large, {
    marginBottom: '35px'
  }),
  logout: css({
    position: 'absolute',
    right: '60px',
    display: 'flex',
    alignItems: 'center'
  })
}

class NoPermissionsPage extends React.Component {
  renderBody = () => {
    const { me: { org = {} } } = this.props

    const { isCatalogEnabledForMembers, isDisabled, companyName } = org

    if (isDisabled) {
      const contactEmail = 'support@toriihq.com'
      return <div>
        Your account is disabled, please contact <strong><Link href={`mailto:${contactEmail}`}>{contactEmail}</Link></strong>
      </div>
    }

    if (!isCatalogEnabledForMembers) {
      return <div>
        It seems that you do not have the required permissions to access {companyName}'s Application Catalog.
        <br />
        Please contact your IT team.
      </div>
    }
  }

  onClickLogout = () => {
    window.location.href = `${config.appBaseUrl}/login/logout?next=${encodeURIComponent(config.catalogBaseUrl)}`
  }

  render () {
    const { me: { firstName } } = this.props

    return (
      <div {...CSS.main}>
        <div {...CSS.logout}>
          <Button type={ButtonType.compact} size={ButtonSize.small} icon='Logout' label='Log out' onClick={this.onClickLogout} />
        </div>
        <img {...CSS.logo} alt='torii' src={ToriiLogo} width='57' height='57' />
        <div {...CSS.title}>Welcome to Torii, {capitalize(firstName)}</div>
        <div {...texts.body}>{this.renderBody()}</div>
      </div>
    )
  }
}

export default NoPermissionsPage
