import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Button, ButtonType, ButtonSize } from '@toriihq/design-system'
import { css } from 'glamor'
import colors from '../../shared/style/colors'
import texts from '../../shared/style/texts'
import { clearButton } from '../../shared/style/mixins'
import ClickOutside from 'react-click-outside'
import noop from 'lodash/noop'
import config from '../../config'
import Analytics from '../../helpers/analytics'

const CSS = {
  main: css({
    position: 'relative'
  }),
  ul: css({
    zIndex: 2,
    position: 'absolute',
    right: 0,
    top: '35px',
    width: '220px',
    backgroundColor: colors.white,
    color: colors.darkText,
    boxShadow: '0 6px 7px 0 rgba(0, 0, 0, 0.11)',
    border: `1px solid ${colors.border}`,
    borderRadius: '2px',
    listStyle: 'none',
    margin: 0,
    padding: '10px 0',
    opacity: 0,
    transform: 'translateY(-5px)',
    transition: 'opacity .2s, transform .1s',
    visibility: 'hidden'
  }),
  isOpen: css({
    opacity: 1,
    visibility: 'visible',
    transform: 'translateY(0)'
  }),
  connectedUser: css({
    backgroundColor: `${colors.lightBlue2} !important`,
    color: `${colors.blue} !important`
  }),
  logOutText: css({
    display: 'flex',
    height: '35px',
    lineHeight: '35px',
    alignItems: 'center',
    paddingLeft: '15px',
    transition: 'background-color .2s',
    ':hover': {
      backgroundColor: colors.background
    }
  }),
  fullName: css(texts.subheading, {
    color: colors.grey1,
    display: 'flex',
    alignItems: 'center'
  })
}

class Menu extends React.Component {
  state = {
    isOpen: false
  }

  onToggle = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }))
  }

  onClickLogout = () => {
    const { firstName, lastName } = this.props
    Analytics.track('User clicked logout', {
      'User name': `${firstName} ${lastName}`
    })
    window.location.href = `${config.appBaseUrl}/login/logout?next=${encodeURIComponent(config.catalogBaseUrl)}`
  }

  render () {
    const { firstName, lastName } = this.props
    const { isOpen } = this.state

    return (
      <ClickOutside onClickOutside={isOpen ? this.onToggle : noop}>
        <div {...CSS.main}>
          <button {...clearButton} onClick={this.onToggle}>
            <div {...CSS.fullName}>{firstName} {lastName} <Icon name='ChevronDown' /></div>
          </button>
          <ul {...css(CSS.ul, isOpen && CSS.isOpen)}>
            <li {...CSS.logOutText}>
              <Button type={ButtonType.compact} size={ButtonSize.small} icon='Logout' label='Log out' onClick={this.onClickLogout} />
            </li>
          </ul>
        </div>
      </ClickOutside>
    )
  }
}

Menu.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string
}

export default Menu
