import View from './view'
import { connect } from 'react-redux'
import { toggleShowAppDetails } from '../../shared/actions'

const mapStateToProps = (state, ownProps) => {
  return {
    showDetails: state.ui.showDetailsForAppId === ownProps.id
  }
}

const mapDispatchToProps = {
  toggleShowAppDetails
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...ownProps,
    toggleShowAppDetails: () => dispatchProps.toggleShowAppDetails(ownProps.id, ownProps.name, stateProps.showDetails)
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
