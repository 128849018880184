import {
  LOAD_USER_PREFERENCES,
  TOGGLE_FILTER_CATEGORY,
  CHANGE_SEARCH,
  TOGGLE_FILTER_MY_APPS,
  TOGGLE_SHOW_APP_DETAILS,
  GET_SIMILAR_APPS,
  SET_SEARCH_LOADING
} from '../constants'
import update from 'immutability-helper'
import without from 'lodash/without'

const initialState = {
  userPreferences: {
    showCategories: [],
    showOnlyMyApps: false
  },
  search: '',
  searchLoading: false,
  showDetailsForAppId: null

}

const uiReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TOGGLE_FILTER_CATEGORY: {
      const { category, shouldAdd } = action.payload
      let newCategoriesList
      if (shouldAdd) {
        newCategoriesList = state.userPreferences.showCategories.concat([category])
      } else {
        newCategoriesList = without(state.userPreferences.showCategories, category)
      }

      return {
        ...state,
        userPreferences: update(state.userPreferences, {
          showCategories: { $set: newCategoriesList }
        }),
        search: ''
      }
    }
    case TOGGLE_FILTER_MY_APPS: {
      const { shouldFilter } = action.payload
      return {
        ...state,
        userPreferences: update(state.userPreferences, {
          showOnlyMyApps: { $set: shouldFilter }
        }),
        search: ''
      }
    }
    case TOGGLE_SHOW_APP_DETAILS: {
      const { idApp } = action.payload
      return {
        ...state,
        showDetailsForAppId: idApp === state.showDetailsForAppId ? null : idApp
      }
    }
    case LOAD_USER_PREFERENCES: {
      const { userPreferences } = action.payload
      const nextUserPreferences = Object
        .keys(userPreferences || {})
        .reduce((result, key) => ({
          ...result,
          [key]: update(state.userPreferences[key] || {}, {
            $set: userPreferences[key]
          })
        }), {})

      return {
        ...state,
        userPreferences: {
          ...state.userPreferences,
          ...nextUserPreferences
        }
      }
    }
    case CHANGE_SEARCH: {
      const { search } = action.payload
      return {
        ...state,
        search,
        searchLoading: Boolean(search),
        userPreferences: {
          showCategories: [],
          showOnlyMyApps: false
        }
      }
    }
    case SET_SEARCH_LOADING: {
      const { isLoading } = action.payload
      return {
        ...state,
        searchLoading: isLoading
      }
    }
    case `${GET_SIMILAR_APPS}_RESPONSE`: {
      return {
        ...state,
        searchLoading: false
      }
    }
    default: {
      return state
    }
  }
}

export default uiReducer
