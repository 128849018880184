import React from 'react'
import { Checkbox, Spacer } from '@toriihq/design-system'

import { CategoryWrapper, Wrapper } from './styles'

class CategoriesFilters extends React.Component {
  constructor (props) {
    super(props)
    this.toggleCategoryFilter = this.toggleCategoryFilter.bind(this)
  }

  shouldComponentUpdate (nextProps, nextState, nextContext) {
    return nextProps.categories.some((category, index) => {
      return this.props.categories[index]
        ? category.show !== this.props.categories[index].show
        : true
    })
  }

  toggleCategoryFilter (e) {
    const { toggleFilterByCategory } = this.props
    const categoryName = e.target.value
    const checked = e.target.checked
    toggleFilterByCategory(categoryName, checked)
  }

  render () {
    const { categories } = this.props

    return (
      <Spacer top={'space-400'}>
        <Wrapper>
          <Spacer bottom={'space-100'}>CATEGORIES</Spacer>
          {categories.map((categoryFilterCheckbox) => {
            const { name, show } = categoryFilterCheckbox

            return (
              <CategoryWrapper key={name}>
                <Spacer top={'space-150'}>
                  <Checkbox
                    value={name}
                    label={name}
                    checked={Boolean(show)}
                    onChange={this.toggleCategoryFilter}
                  />
                </Spacer>
              </CategoryWrapper>
            )
          })}
        </Wrapper>
      </Spacer>
    )
  }
}

export default CategoriesFilters
