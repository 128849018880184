const identify = (props) => {
  window.Raven && window.Raven.setUserContext(props)
}

let sentry = null
if (window.Raven) {
  sentry = {
    identify
  }
}

export default sentry
