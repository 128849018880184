import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import App from '../app'
import sortBy from 'lodash/sortBy'
import { appProps } from '../app/view'
import { MOBILE_BREAKPOINT } from '../../constants'

const CSS = {
  container: css({
    gridTemplateColumns: 'repeat(3, 1fr)',
    display: 'grid',
    gridColumnGap: 20,
    [`@media(max-width: ${MOBILE_BREAKPOINT})`]: {
      gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))'
    }
  })
}

const Apps = ({ apps, loading }) => {
  const sortedByNameApps = sortBy(apps, ['name'])

  return <div {...CSS.container}>
    {sortedByNameApps.map(app => <App {...app} loading={loading} key={app.id} />)}
  </div>
}

Apps.propTypes = {
  apps: PropTypes.arrayOf(PropTypes.shape(appProps))
}

export default Apps
