import View from './view'
import { connect } from 'react-redux'
import {
  toggleFilterByCategory
} from '../../shared/actions'

const mapDispatchToProps = {
  toggleFilterByCategory
}

export default connect(null, mapDispatchToProps)(View)
