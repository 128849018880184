import { Wrapper } from './styles'

const RequestAcessDetailsTitle = () => {
  return (
    <Wrapper>
      <div>
        Please provide more details about your request:
      </div>
      <div>
        1. Which license or permissions do you need, and why.
      </div>
      <div>
        2. Indicate whether you need the app for a limited period of time.
      </div>
    </Wrapper>
  )
}

export default RequestAcessDetailsTitle
