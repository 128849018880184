import View from './view'
import { connect } from 'react-redux'
import isNumber from 'lodash/isNumber'
import { searchApps } from '../../shared/actions'
import { getCurrentOrgId } from '../../selectors/org'
import { deprecatedGetAppsByIds, getUsedAppsByIds } from '../../selectors/apps'

const mapStateToProps = (state, ownProps) => {
  const idOrg = getCurrentOrgId(state)
  const usedAppsById = getUsedAppsByIds(state)
  const appsById = deprecatedGetAppsByIds(state)
  const value = isNumber(ownProps.value) ? appsById[ownProps.value] : ownProps.value

  return {
    idOrg,
    usedAppsById,
    value
  }
}

const mapDispatchToProps = {
  searchApps
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
