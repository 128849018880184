import { withRouter } from 'react-router-dom'
import View from './view'
import { connect } from 'react-redux'
import { getSelf } from '../../selectors/me'
import get from 'lodash/get'

const mapStateToProps = (state) => ({
  nickname: get(getSelf(state), 'org.nickname', '')
})

export default withRouter(connect(mapStateToProps)(View))
