import React from 'react'
import PropTypes from 'prop-types'
import { Spacer } from '@toriihq/design-system'

import ToriiPopup from '../ToriiPopupV2'
import MyAppsFilter from '../../myAppsFilter'
import CategoriesFilters from '../../categoriesFilters'

import { Wrapper } from './style'

const FiltersPopup = ({ cancel, isOpen, categories, loading }) =>
  (<ToriiPopup
    isOpen={isOpen}
    onCloseAction={cancel}
    styles={{ modal: { maxWidth: '80%', minWidth: '280px' } }}
  >
    <ToriiPopup.Content>
      <Wrapper>
        <Spacer top={'space-150'}>
          <MyAppsFilter />
        </Spacer>
      </Wrapper>
      <CategoriesFilters categories={categories} />
    </ToriiPopup.Content>
    <ToriiPopup.Footer
      mainButtonAction={cancel}
      mainButtonText='Save'
      showCancelButton={false} />
  </ToriiPopup>)

FiltersPopup.propTypes = {
  cancel: PropTypes.func,
  isOpen: PropTypes.bool,
  categories: PropTypes.array,
  loading: PropTypes.bool
}

export default FiltersPopup
