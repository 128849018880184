import { css } from 'glamor'
import colors from './colors'

const texts = {
  headers: {
    xLargeBold: css({
      fontSize: '40px',
      fontWeight: 600
    }),
    xLarge: css({
      fontSize: '40px',
      fontWeight: 300
    }),
    large: css({
      fontSize: '25px',
      fontWeight: 600
    }),
    semiLarge: css({
      fontSize: '20px',
      fontWeight: 600
    }),
    regular: css({
      fontSize: '18px',
      fontWeight: 600
    }),
    small: css({
      fontSize: '16px',
      fontWeight: 600
    })
  },
  heading: css({
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 600,
    color: colors.black
  }),
  subheading: css({
    fontSize: '13px',
    lineHeight: '18px',
    fontWeight: 600,
    color: colors.grey2
  }),
  body: css({
    color: colors.text,
    fontSize: '13px',
    lineHeight: '18px',
    fontWeight: 400,
    ' strong': {
      fontWeight: 700
    }
  }),
  caption: css({
    fontSize: '10px',
    fontWeight: 400
  })
}

export default texts
