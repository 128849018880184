import React from 'react'
import { Checkbox, Spacer } from '@toriihq/design-system'

import { Label } from './styles'
import StarImage from '@media/star.svg'

class MyAppsFilter extends React.Component {
  constructor (props) {
    super(props)
    this.toggleMyAppsFilter = this.toggleMyAppsFilter.bind(this)
  }

  toggleMyAppsFilter (e) {
    const { toggleMyAppsFilterAction } = this.props
    toggleMyAppsFilterAction(e.target.checked)
  }

  render () {
    const { filterChecked } = this.props
    const label = (
      <Label>
        <Spacer right={'space-150'}>
          <img src={StarImage} width={16} height={16} alt='star' />
        </Spacer>
        My Apps
      </Label>)

    return (
      <Checkbox onChange={this.toggleMyAppsFilter} checked={Boolean(filterChecked)} label={label} />
    )
  }
}

export default MyAppsFilter
