import React from 'react'
import { Route, Redirect } from 'react-router-dom'

class TeamFallbackRoute extends React.Component {
  constructor (props) {
    super(props)
    this.redirectWhenNicknameIsUnknown = this.redirectWhenNicknameIsUnknown.bind(this)
  }

  redirectWhenNicknameIsUnknown () {
    const { nickname } = this.props
    const path = window.location.pathname
    const to = path.replace('/', `/${nickname}`)
    return <Redirect to={to} />
  }

  render () {
    return (
      <div>
        <Route path='/:rest?' render={this.redirectWhenNicknameIsUnknown} />
      </div>
    )
  }
}

export default TeamFallbackRoute
