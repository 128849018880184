/*
 this function utilizes the Fetch API to send an HTTP request,
 while communicating its state to the Redux store (START -> SUCCESS/FAIL).
 it expects to receive a JSON formatted response.
 _transform_ may be a function which modifies the JSON response
 before dispatching it to the store.

 All dispatched actions comply with the Flux Standard Action (https://github.com/acdlite/flux-standard-action)
 */

/*
 reduxFetch default values
 */
let configuration = {
  startSuffix: '_PENDING',
  successSuffix: '_SUCCESS',
  failSuffix: '_FAILED',
  baseUrl: 'http://localhost',
  defaultHeaders: {},
  credentials: 'include'
}

/*
 reduxFetch default values can be configured
 */
export const configure = config => {
  configuration = {
    ...configuration,
    ...config
  }
}

// headers can either be values, or functions which would be evaluated at run time
const evaluateHeaders = async headers => {
  const evaluatedHeaders = {}
  await Promise.all(
    Object.keys(headers).map(key => {
      const value = headers[key]
      return Promise.resolve(
        typeof value === 'function' ? value() : value)
        .then(resolution => {
          evaluatedHeaders[key] = resolution
        })
    }))
  return evaluatedHeaders
}

const reduxFetch = (type, {
  url,
  meta,
  method = 'GET',
  query,
  body,
  headers,
  transform
}) => async dispatch => {
  const { startSuffix, failSuffix, successSuffix, baseUrl, defaultHeaders, credentials } = configuration
  let queryString = query
  if (typeof query === 'object') {
    queryString = Object.keys(query).map(key => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`
    }).join('&')
  }

  let urlWithProtocol
  if (url.includes('://')) {
    urlWithProtocol = url
  } else {
    urlWithProtocol = `${baseUrl}${url}`
  }

  const parsedUrl = queryString
    ? `${urlWithProtocol}?${queryString}`
    : urlWithProtocol
  dispatch(
    {
      type: `${type}${startSuffix}`,
      meta,
      payload: query ? { query } : {}
    })
  const evaluatedHeaders = await evaluateHeaders({
    ...defaultHeaders,
    ...headers || {}
  })
  if (body) {
    body = Object.prototype.toString.call(body) !== '[object FormData]' ? JSON.stringify(body) : body
  }
  return window.fetch(parsedUrl, {
    method,
    body,
    headers: evaluatedHeaders,
    credentials
  })
    .then(response => {
      if (!response.ok) {
        return response.text()
      }
      return response.json()
    })
    .then(data => {
      if (typeof data === 'string') {
        throw new Error(data)
      }
      const payload = transform ? transform(data) : data
      dispatch({ type: `${type}${successSuffix}`, meta, payload })
      return payload
    })
    .catch(e => {
      let payload
      try {
        payload = JSON.parse(e.message)
      } catch (_) {
        payload = e.message
      }
      dispatch({
        type: `${type}${failSuffix}`,
        meta,
        payload,
        error: true
      })
      throw e
    })
}

export default reduxFetch
