import get from 'lodash/get'
import {
  GET_ME,
  AUTH_STATE
} from '../constants'

const initialState = {
  authState: AUTH_STATE.BEFORE_AUTH
}

const meReducer = (state = initialState, action) => {
  if (action.type.endsWith('_FAILED')) {
    const statusCode = get(action, 'payload.statusCode')
    if (statusCode === 401 && state.authState === AUTH_STATE.AUTHENTICATED) {
      return {
        ...state,
        authState: AUTH_STATE.NO_LONGER_AUTHENTICATED
      }
    }
  }

  switch (action.type) {
    case `${GET_ME}_PENDING`: {
      return {
        ...state,
        authState: AUTH_STATE.PENDING
      }
    }
    case `${GET_ME}_RESPONSE`: {
      return {
        ...state,
        ...action.payload,
        authState: AUTH_STATE.AUTHENTICATED
      }
    }
    case `${GET_ME}_FAILED`: {
      return {
        ...state,
        authState: AUTH_STATE.NOT_AUTHENTICATED
      }
    }
    default: {
      return state
    }
  }
}

export default meReducer
