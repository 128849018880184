import View from './view'
import { connect } from 'react-redux'
import {
  changeSearch,
  getSimilarApps,
  setSearchLoading
} from '../../shared/actions'
import { getCurrentOrg } from '../../selectors/org'

const mapStateToProps = (state) => {
  const { id: idOrg, supportedFeatures } = getCurrentOrg(state) || {}
  const { search, searchLoading } = state.ui

  return {
    idOrg,
    query: search,
    searchLoading,
    supportedFeatures
  }
}

const mapDispatchToProps = {
  changeSearch,
  getSimilarApps,
  setSearchLoading
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
