import { LOAD_USER_PREFERENCES } from '../constants'

const LOCAL_STORAGE_KEY = 'userPreferencesCatalog'
const IS_LOCAL_STORAGE_SUPPORTED = Boolean(window && window.localStorage && window.localStorage.getItem && window.localStorage.setItem)

const storageMiddleWare = ({ getState }) => next => action => {
  if (!IS_LOCAL_STORAGE_SUPPORTED) {
    return next(action)
  }

  const prevUserPreferences = getState().ui.userPreferences

  if (action.type === LOAD_USER_PREFERENCES) {
    const userPreferences = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEY) || '{}')
    next({
      ...action,
      payload: { userPreferences }
    })
    return
  }

  next(action)

  const nextUserPreferences = getState().ui.userPreferences
  if (prevUserPreferences !== nextUserPreferences) {
    window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(nextUserPreferences))
  }
}

export default storageMiddleWare
