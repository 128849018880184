import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import { AppIcon } from '@toriihq/design-system'
import colors from '../../shared/style/colors'
import texts from '../../shared/style/texts'
import Placeholder from '../placeholder'
import StarImage from '@media/star.svg'

const CSS = {
  container: css({
    display: 'grid',
    gridTemplateColumns: '40px fit-content(100%)',
    alignItems: 'center',
    height: '90px',
    width: '100%',
    border: `1px solid ${colors.border}`,
    borderRadius: '4px',
    backgroundColor: colors.white,
    marginBottom: '20px',
    padding: '20px',
    textAlign: 'left',
    ':hover': {
      border: `1px solid ${colors.blue}`,
      cursor: 'pointer'
    }
  }),
  appName: css(texts.heading, {
    marginLeft: 15,
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }),
  star: css({
    marginLeft: '5px'
  }),
  selected: css({
    border: `1px solid ${colors.blue}`,
    backgroundColor: colors.background
  })
}

const App = ({ name, imageUrl, isMyApp, showDetails, toggleShowAppDetails, loading }) => {
  return (
    <Fragment>
      <Placeholder type='rect' loading={loading} rows={1} style={{ height: '90px', maxWidth: '100%', margin: '28px 0' }}>
        <button type='button' {...css(CSS.container, showDetails && CSS.selected)} onClick={toggleShowAppDetails}>
          <AppIcon size='large' appName={name} appImageUrl={imageUrl} />
          <div {...CSS.appName}>
            <span>{name}</span>
            {isMyApp && <img src={StarImage} width={16} height={16} alt='star' {...CSS.star} />}
          </div>
        </button>
      </Placeholder>
    </Fragment>
  )
}

export const appProps = {
  id: PropTypes.number,
  name: PropTypes.string,
  category: PropTypes.string,
  imageUrl: PropTypes.string,
  isMyApp: PropTypes.bool,
  loading: PropTypes.bool
}

App.propTypes = {
  ...appProps,
  showDetails: PropTypes.bool,
  toggleShowAppDetails: PropTypes.func.isRequired
}

export default App
